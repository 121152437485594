<template>
  <v-data-table
    ref="dataTable"
    class="elevation-1"
    :headers="headers"
    :items="pages"
    :items-per-page="pageSize"
    :loading="!hideLoading ? $store.state.loading.show : false"
    :ripple="false"
    fixed-header
    :single-expand="true"
    show-expand
    :height="height ? 'auto' : '100%'"
    :style="{
      height: height ? 'auto' : '100%',
      overflow: 'auto',
    }"
    hide-default-footer
    :hide-default-header="hideHeader"
    loading-text="جار التحميل..."
    no-data-text="لا يوجد"
  >
    <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
      <template v-if="item.contentType.isNestedContent">
        <v-btn
          v-if="!isExpanded"
          icon
          @click="
            () => {
              $emit('getChildData', item);
              expand(!isExpanded);
            }
          "
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn v-if="isExpanded" icon @click="expand(false)">
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </template>
    </template>
    <template v-slot:expanded-item="{ headers: h, item }">
      <td
        v-if="item.contentType.isNestedContent"
        class="py-2"
        :colspan="h.length"
      >
        <DynamicContentTable
          v-if="item.children && item.children.length > 0"
          :headers="headers"
          :pages="item.children"
          :pageSize="pageSize"
          :isCanUpdate="isCanUpdate"
          :isCanDelete="isCanDelete"
          :hideHeader="true"
          @getChildData="$emit('getChildData', $event)"
          @addChildren="addChildren"
          @editRow="editRow"
          @deleteRow="deleteRow"
          :hideLoading="true"
          :height="true"
        />
        <div
          v-else-if="
            (!item.children || item.children.length === 0) &&
            !$store.state.loading.show
          "
          class="text-center"
        >
          <b>لا يوجد بيانات</b>
        </div>
      </td>
    </template>
    <template v-slot:[`item.categoryNames`]="{ item }">
      <v-chip
        v-for="(name, index) in item.categoryNames"
        :key="index"
        class="ml-1"
        label
        small
      >
        {{ name }}
      </v-chip>
    </template>

    <template v-slot:[`item.publishDate`]="{ item }">
      <span dir="ltr">{{ $service.formatDate(item.publishDate) }}</span>
    </template>

    <template v-slot:[`item.controls`]="props">
      <template v-if="props.item.isDeleted">
        <v-btn
          small
          color="red"
          @click="cancelDeleteRow(props.item)"
          class="mr-1"
        >
          <v-icon color="#fff">mdi-arrow-u-left-bottom</v-icon>
        </v-btn>
      </template>

      <template v-else>
        <template v-if="props.item.contentType.isNestedContent">
          <!-- add button -->
          <v-btn
            small
            color="#78b200"
            @click="addChildren(props.item.id)"
            class="mr-1"
          >
            <v-icon color="#fff">mdi-plus</v-icon>
          </v-btn>
        </template>

        <template v-if="isCanUpdate">
          <v-btn
            small
            color="#ffa000"
            @click="editRow(props.item)"
            class="mr-1"
          >
            <v-icon color="#fff">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>

        <template v-if="isCanDelete">
          <v-btn small color="pink" @click="deleteRow(props.item)" class="mr-1">
            <v-icon color="#fff">mdi-delete</v-icon>
          </v-btn>
        </template>

        <template>
          <SendNotificationDialog
            :contentId="props.item.id"
            :afterSubmit="() => {}"
          />
        </template>
      </template>
    </template>

    <template v-slot:[`item.contentStatus`]="props">
      <v-btn
        target="_blank"
        icon
        small
        :href="
          checkStatusIsPublish(props.item) ? getPublicLink(props.item) : null
        "
      >
        <v-icon>
          {{ checkStatusIsPublish(props.item) ? 'mdi-eye' : 'mdi-eye-off' }}
        </v-icon>
      </v-btn>
    </template>

    <template v-slot:[`item.language.title`]="props">
      <div
        class="d-flex flex-column"
        style="gap: 10px; font-size: 1rem; margin-bottom: 6px"
      >
        <div class="mt-1">
          {{ getTitle(props.item) }}
        </div>
        <div class="d-flex flex-row" style="gap: 3px">
          <v-chip x-small dir="ltr" :class="getContentStatusColor(props.item)">
            {{ getStatusName(props.item) }}
          </v-chip>
          <v-chip x-small>
            <v-icon x-small class="ml-1">mdi-account</v-icon>
            {{ getUserName(props.item) }}
          </v-chip>

          <v-chip x-small dir="ltr">
            {{ $service.formatDate(props.item.creationTime) }}
            <v-icon x-small class="ml-1">mdi-calendar</v-icon>
          </v-chip>
        </div>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import SendNotificationDialog from '@/views/Notifications/dialog/SendNotificationDialog.vue';

export default {
  name: 'DynamicContentTable',
  props: [
    'headers',
    'pages',
    'pageSize',
    'isCanUpdate',
    'isCanDelete',
    'hideHeader',
    'height',
    'hideLoading',
    'dataChanged',
  ],
  components: {
    DynamicContentTable: () =>
      import('@/views/dynamicContent/components/DynamicContentTable.vue'),
    SendNotificationDialog,
  },
  data() {
    return {};
  },
  watch: {
    dataChanged() {
      // close expanded
      this.$nextTick(() => this.$refs.dataTable.expand(false));
    },
  },
  methods: {
    addChildren(id) {
      this.$emit('addChildren', id);
    },
    editRow(item) {
      this.$emit('editRow', item);
    },
    deleteRow(item) {
      this.$emit('deleteRow', item);
    },
    cancelDeleteRow(item) {
      this.$emit('cancelDeleteRow', item);
    },
    getTitle(item) {
      return item?.language?.title || '';
    },
    getContentStatusColor(item) {
      const { contentStatus } = item;
      const { slug } = contentStatus || {};
      if (slug === 'PublishStatus') return 'green white--text';
      if (slug === 'PendingStatus' || slug === 'CloseStatus')
        return 'red white--text';
      if (slug === 'DraftStatus') return 'yellow black--text';
      return 'grey';
    },
    getStatusName(item) {
      return item?.contentStatus?.contentStatusLanguages?.[0]?.name || '';
    },
    getUserName(item) {
      return item?.creator?.userName || '';
    },
    checkStatusIsPublish(item) {
      return item?.contentStatus?.slug == 'PublishStatus';
    },
    getPublicLink(item) {
      const { slug: categorySlug } = item?.categories?.at?.(0)?.category || {};
      const { slug: itemSlug } = item;

      if (!categorySlug || !itemSlug) return;

      // todo: get it from settings
      return `http://164.92.187.207:5007/ar/category/${categorySlug}/${itemSlug}`;
    },
  },
};
</script>

<style>
.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
