<template>
  <v-card
    elevation="0"
    outlined
    :style="!render && cardStyle"
    class="fill-height"
  >
    <v-container v-if="!render">
      <v-row>
        <v-col cols="12">
          <v-btn
            fab
            absolute
            top
            left
            small
            color="pink"
            class="mr-1 white--text"
            :loading="cardStyle.borderColor == 'red'"
            @click="removeField"
            v-if="canDeleteField"
          >
            <v-icon color="#fff">mdi-delete</v-icon>
          </v-btn>
          <FieldsLanguages
            v-model="field"
            :selectedLanguage="selectedLanguage"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field v-model="field.slug" label="slug" outlined dense />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-select
            v-model="field.type"
            :items="inputTypes"
            label="النوع"
            placeholder="اختر النوع"
            outlined
            dense
            :error-messages="typeErrors"
            @input="$v.field.type.$touch()"
            @blur="$v.field.type.$touch()"
          />
        </v-col>

        <v-col cols="4">
          <v-text-field
            type="number"
            v-model="field.colsNumber"
            label="عدد الاعمدة"
            placeholder="عدد الاعمدة"
            required
            outlined
            dense
            :counter="2"
            :error-messages="colsNumberErrors"
            @input="$v.field.colsNumber.$touch()"
            @blur="$v.field.colsNumber.$touch()"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="field.value"
            :error-messages="valueErrors"
            label="القيمة"
            outlined
            dense
            @input="$v.field.value.$touch()"
            @blur="$v.field.value.$touch()"
          />
        </v-col>
      </v-row>

      <v-row class="px-10 my-0">
        <v-col class="py-0">
          <v-checkbox
            v-model="field.isRequired"
            color="red"
            label="القيمة مطلوبة ؟"
            dense
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>

    <template v-if="render">
      <FieldRender
        v-model="field.value"
        :field="field"
        :selectedLanguage="selectedLanguage"
        :valueErrors="valueErrors"
        @input="$v.field.value.$touch()"
        @blur="$v.field.value.$touch()"
      />
    </template>
  </v-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  maxLength,
  maxValue,
  minValue,
  email,
} from 'vuelidate/lib/validators';

const mustHasValue = (param) => (value) => {
  if (!param) return true; // pass : no need to validate
  if (!value || value.length == 0) return false; // not pass
  return true; // pass
};

import FieldsLanguages from './FieldLanguages.vue';
import { inputTypes } from './utls/FieldTypes';
import FieldRender from './FieldRender.vue';
import { makeid } from '@/utls/jsCommon';

export default {
  props: ['data', 'selectedLanguage', 'hideDeleteField', 'render'],
  components: {
    FieldsLanguages,
    FieldRender,
  },
  mixins: [validationMixin],
  data() {
    return {
      field: {
        name: {}, // multi languages { ar: value, en: value }
        description: {}, // multi languages { ar: value, en: value }
        type: '',
        colsNumber: '12',
        isRequired: false,
        value: '',
        slug: '',
      },
      inputTypes,
      cardStyle: {
        border: '2px solid',
        borderColor: 'var(--primary-color)',
      },
    };
  },
  computed: {
    typeErrors() {
      const errors = [];
      if (!this.$v.field.type.$dirty) return errors;
      !this.$v.field.type.required && errors.push('الحقل مطلوب');
      return errors;
    },
    colsNumberErrors() {
      const errors = [];
      if (!this.$v.field.colsNumber.$dirty) return errors;
      !this.$v.field.colsNumber.maxLength &&
        errors.push('لايمكن تجاوز الحد المسموح');
      !this.$v.field.colsNumber.maxValue && errors.push('غير مسموح اكبر من 12');
      !this.$v.field.colsNumber.minValue && errors.push('غير مسموح اصغر من 2');
      !this.$v.field.colsNumber.required && errors.push('الحقل مطلوب');
      return errors;
    },
    isNeedValue() {
      return this.render && this.field.isRequired;
    },
    valueErrors() {
      const errors = [];
      if (!this.$v.field.value.$dirty) return errors;
      if (!this.$v.field.value.hasValue) errors.push('الحقل مطلوب');
      return errors;
    },
    language() {},
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
  },
  validations() {
    return {
      field: {
        type: { required },
        value: { hasValue: mustHasValue(this.isNeedValue) },
        colsNumber: {
          required,
          maxLength: maxLength(2),
          maxValue: maxValue(12),
          minValue: minValue(2),
        },
      },
    };
  },
  methods: {
    removeField() {
      this.cardStyle.borderColor = 'red';
      setTimeout(() => this.$emit('remove', this.field.id), 600);
    },
    initField() {
      let field = { ...this.field };
      if (this.data) field = { ...field, ...this.data };

      if (this.selectedLanguage) {
        ['name', 'description'].forEach((key) => {
          for (const languageCode in field[key]) {
            if (this.selectedLanguage != languageCode) {
              delete field[key][languageCode];
            }
          }
        });
      }

      this.field = field;
      if (!this.field.slug) this.field.slug = makeid(15);
    },
  },
  created() {
    this.initField();
  },
};
</script>

<style lang="scss" scoped></style>
