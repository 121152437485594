<template>
  <v-card elevation="0" outlined :style="cardStyle">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-btn
            fab
            absolute
            top
            left
            small
            color="pink"
            class="mr-1 white--text"
            :loading="cardStyle.borderColor == 'red'"
            @click="removeField"
            v-if="canDeleteField"
          >
            <v-icon color="#fff">mdi-delete</v-icon>
          </v-btn>
          <FileCardLanguages
            v-model="field"
            :selectedLanguage="selectedLanguage"
          />
        </v-col>
      </v-row>
      <v-row v-if="!render">
        <v-col cols="12">
          <v-text-field v-model="field.slug" label="slug" outlined />
        </v-col>

        <v-col cols="12">
          <v-text-field
            type="number"
            v-model="field.order"
            label="الترتيب"
            placeholder="الترتيب"
            required
            outlined
            :error-messages="orderErrors"
            @input="$v.field.order.$touch()"
            @blur="$v.field.order.$touch()"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { makeid } from '@/utls/jsCommon';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import FileCardLanguages from './FileCardLanguages.vue';

export default {
  props: ['data', 'selectedLanguage', 'hideDeleteField', 'render'],
  components: {
    FileCardLanguages,
  },
  mixins: [validationMixin],
  validations: {
    field: {
      order: {
        required,
      },
      // src: {
      //   required,
      // },
    },
  },
  data() {
    return {
      field: {
        description: {}, // multi languages { ar: value, en: value }
        order: '1',
        src: '',
        slug: '',
      },
      cardStyle: {
        border: '2px solid',
        borderColor: 'var(--primary-color)',
        padding: '20px 10px 0',
      },
    };
  },
  computed: {
    orderErrors() {
      const errors = [];
      if (!this.$v.field.order.$dirty) return errors;
      !this.$v.field.order.required && errors.push('الحقل مطلوب');
      return errors;
    },
    // srcErrors() {
    //   const errors = [];
    //   if (!this.$v.field.src.$dirty) return errors;
    //   !this.$v.field.src.required && errors.push('الحقل مطلوب');
    //   return errors;
    // },
    language() {},
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
  },

  methods: {
    removeField() {
      this.cardStyle.borderColor = 'red';
      setTimeout(() => this.$emit('remove', this.data.id), 600);
    },
    initField() {
      if (this.data) this.field = this.data;
      if (!this.field.slug) this.field.slug = makeid(15);
    },
    changeCardBorderColor() {
      const isDark = this.$store.state.theme.dark;

      let bcolor = 'var(--primary-color)';
      if (this.render && isDark) bcolor = '#555';
      else if (this.render) bcolor = '#aaaccc';

      this.cardStyle.borderColor = bcolor;
    },
  },
  created() {
    this.initField();
  },
  mounted() {
    this.changeCardBorderColor();
  },
};
</script>

<style lang="scss" scoped></style>
