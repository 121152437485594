<template>
  <div :style="groupStyle">
    <v-btn
      v-if="canRemoveGroup"
      fab
      absolute
      top
      left
      small
      color="pink"
      class="mr-1 white--text"
      :loading="groupStyle.borderColor == 'red'"
      @click="removeGroup(groupFields.id)"
    >
      <v-icon color="#fff">mdi-delete</v-icon>
    </v-btn>
    <v-btn
      fab
      absolute
      top
      left
      small
      color="grey"
      class="mr-1"
      style="left: 70px"
      @click="expandGroup(groupFields.id)"
    >
      <v-icon color="#fff" v-html="expandIcon" />
    </v-btn>

    <!-- 
    <div class="my-5 px-5">
      <h2>{{ schemaName }}</h2>
    </div> 

    <v-row>
      <v-col cols="12">
        <hr :style="'width:' + (80 + schemaName.length * 10) + 'px'" />
      </v-col>
    </v-row>
    -->

    <v-expand-transition>
      <v-row v-show="isExpand">
        <v-col
          :cols="field.data.colsNumber || 12"
          v-for="field in groupFields.values"
          :key="field.data.id"
        >
          <slot :field="field" />
        </v-col>
      </v-row>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    groupFields: {
      type: Object,
      required: true,
    },
    canRemoveGroup: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      groupStyle: {
        border: '2px solid',
        borderColor: 'var(--primary-color)',
        margin: '10px',
        padding: '10px',
        width: '100%',
        borderRadius: '5px',
        position: 'relative',
      },
      isExpand: true,
    };
  },
  computed: {
    schemaName() {
      return this.groupFields?.schemaName || '';
    },
    expandIcon() {
      if (this.isExpand) return 'mdi-chevron-up';
      else return 'mdi-chevron-down';
    },
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    removeGroup(groupId) {
      this.openConfirm({
        title: 'الغاء الهيكلبة',
        message: 'هل انت متأكد من الغاء الهيكلية ؟',
        subMessage: 'سوف يتم فقد جميع القيم المدخلة !',
        confirm: () => {
          this.groupStyle.borderColor = 'red';
          setTimeout(() => this.$emit('removeGroup', groupId), 600);
          return true;
        },
      });
    },
    expandGroup(groupId) {
      this.isExpand = !this.isExpand;
    },
  },
};
</script>

<style lang="scss" scoped></style>
