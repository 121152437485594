<template>
  <v-combobox
    v-model="modelValue"
    :filter="filter"
    :hide-no-data="!search"
    :items="items"
    :search-input.sync="search"
    hide-selected
    :label="label"
    multiple
    dense
    hide-details="auto"
    small-chips
    outlined
  >
    <!-- options item style -->
    <template #item="{ item }">
      <span> {{ item }}</span>
    </template>

    <!-- options style on no data -->
    <template v-slot:no-data>
      <v-list-item>
        <span class="subheading ml-2 caption">اضافة جديد</span>
        <v-chip color="teal lighten-3" label small>
          {{ search }}
        </v-chip>
      </v-list-item>
    </template>

    <!-- selected style -->
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
        v-bind="attrs"
        :color="itemColor || 'cyan lighten-3'"
        :input-value="selected"
        label
        small
      >
        <slot v-if="$scopedSlots.item" name="item" :item="item" />
        <span v-else class="pr-2"> {{ item }} </span>
        <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  props: ['value', 'label', 'itemColor'],
  data: () => ({
    items: [],
    search: null,
  }),
  methods: {
    createNewItem({ text }) {
      return { text };
    },
    filter(item, queryText, itemText) {
      const hasValue = (val) => (val != null ? val : '');
      const isThere = (query, val) =>
        val.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1;
      const query = hasValue(queryText);
      return isThere(query, itemText);
    },
  },
  computed: {
    modelValue: {
      get() {
        if (!this.value) return [];
        const value = this.value.split(',');
        return value;
      },
      set(value) {
        const newValue = value.join(',');
        this.$emit('input', newValue);
      },
    },
  },
};
</script>
