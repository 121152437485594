<template>
  <v-card
    elevation="2"
    color="background"
    class="d-flex flex-column"
    style="height: 100%"
  >
    <v-card-title>
      {{ dynamicTitle }}
      <v-spacer></v-spacer>

      <DefaultDialog
        ref="defaultDialogRef"
        :showAddButton="isCanCreate"
        buttonText="اضافة"
        title="اضافة محتوى"
        :openToEdit="openToEdit"
        editTitle="تعديل محتوى"
        :openToView="openToView"
        viewTitle="عرض محتوى"
        @onCloseDialog="onCloseDialog"
        :toggleSize="true"
      >
        <DynamicContentDetail
          slot-scope="slotProps"
          :submitWithClose="slotProps.submitWithClose"
          :submitWithNew="slotProps.submitWithNew"
          :submitWithoutClose="slotProps.submitWithoutClose"
          :setItemID="setItemID"
          :submit="slotProps.submit"
          :closeDialog="slotProps.closeDialog"
          :afterSubmit="getData"
          :itemId="itemId"
          :isEdit="slotProps.isEdit"
          :isView="slotProps.isView"
          :isNew="slotProps.isNew"
          :schemaTypeId="contentTypeId"
          :parentId="parentId"
        />
      </DefaultDialog>
    </v-card-title>
    <v-card-text
      class="d-flex flex-column justify-space-between"
      style="flex: 1; min-height: 0"
    >
      <!-- Filters -->
      <v-row style="flex: 0">
        <v-col class="d-flex">
          <languages-filter @change="changeLanguage" ref="languagesFilterRef" />
          <div class="mx-2" />
          <deleted-filter @change="changeIsDeleted" ref="deletedFilterRef" />
          <div class="mx-2" />
          <feature-sticky-filter
            @change="changeIsFeatureIsSticky"
            ref="featureStickyFilterRef"
          />
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="filters.title"
            dense
            flat
            hide-details="auto"
            label="بحث"
            outlined
            prepend-inner-icon="mdi-magnify"
            class="mr-1"
            solo
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Grid -->
      <v-row class="mt-0" style="min-height: 0">
        <v-col cols="12" style="flex: auto; min-height: 0; height: 100%">
          <DynamicContentTable
            :headers="headers"
            :pages="pages"
            :pageSize="options.pageSize"
            :isCanUpdate="isCanUpdate"
            :isCanDelete="isCanDelete"
            :dataChanged="dataChanged"
            @cancelDeleteRow="cancelDeleteRow"
            @deleteRow="deleteRow"
            @editRow="editRow"
            @addChildren="addChildren"
            @getChildData="getChildData"
          />
        </v-col>
      </v-row>

      <!-- pagination -->
      <v-row class="mt-0" style="flex: 0">
        <v-col cols="12" class="d-flex justify-space-between align-center">
          <v-pagination
            v-model="options.pageIndex"
            :length="numberOfPages"
            total-visible="7"
          />
          <v-select
            v-model="options.pageSize"
            :items="[5, 10, 15, 20]"
            label="عدد الصفوف"
            solo
            dense
            hide-details="auto"
            item-color="white"
            class="flex-grow-0"
            style="width: 100px"
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import qs from 'qs';
import { selectedLanguage } from '@/config/config';
import { mapActions } from 'vuex';
import {
  showSnackbarFailMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';
import { getDataFromJSON } from '@/components/BaseEditor.vue';
import { viewHTMLContent } from '@/utls/chkEditor';
import DefaultDialog from '@/components/DefaultDialog.vue';
import DynamicContentDetail from './crud/DynamicContentDetail.vue';
import LanguagesFilter from '@/components/LanguagesFilter.vue';
import DeletedFilter from '@/components/DeletedFilter.vue';
import SendNotificationDialog from '@/views/Notifications/dialog/SendNotificationDialog.vue';
import PermissionMixin from '@/mixins/PermissionMixin';
import { appPermissions } from '@/libs/auth/permissions/appPermissions';
import axios from 'axios';
import FeatureStickyFilter from './components/FeatureStickyFilter.vue';
import DynamicContentTable from './components/DynamicContentTable.vue';
// import utils from '@/utls/utils';

const initFilters = {
  title: '',
};

const initOptions = {
  pageIndex: 1,
  pageSize: 15,
  language: selectedLanguage,
  isDeleted: false,
  isFeature: false,
  isSticky: false,
  ...initFilters,
};

let ourGetRequest = null;

export default {
  props: ['title', 'contentTypeId', 'contentTypePermissions'],
  components: {
    LanguagesFilter,
    DynamicContentDetail,
    DefaultDialog,
    DeletedFilter,
    SendNotificationDialog,
    FeatureStickyFilter,
    DynamicContentTable,
  },
  data() {
    return {
      sel: null,
      pages: [],
      total: 0,
      headers: [
        { text: 'id', value: 'id' },
        // { text: 'slug', value: 'slug', width: '150px' },
        // { text: 'slug', value: 'slug' },
        { text: '', value: 'contentStatus', width: '5px' },
        { text: 'العنوان', value: 'language.title' },
        // { text: 'التفاصيل', value: 'language.description' },
        // { text: 'الكلمات المفتاحية', value: 'language.keyword' },
        // { text: 'النوع', value: 'contentType.slug' },
        // { text: 'اسم النوع', value: 'contentTypeLanguage.name' },
        // { text: 'الصنف', value: 'category.slug' },
        { text: 'اسم الصنف', value: 'categoryNames' },
        { text: 'تاريخ النشر', value: 'publishDate', width: '100px' },
        { text: '', value: 'controls', sortable: false, width: '250px' },
        // { text: 'تاريخ الانشاء', value: 'creationTime' },
      ],
      options: { ...initOptions },
      filters: { ...initFilters },
      // for view edit and detail
      // ------------------------
      openToEdit: false,
      openToView: false,
      itemId: null,
      parentId: null,
      // ------------------------
      searchTimer: null,
      dataChanged: 0,
    };
  },
  mixins: [
    PermissionMixin(appPermissions.dynamicContent, {
      name: 'dynamicContentPermissions',
    }),
  ],
  computed: {
    dynamicTitle() {
      if (this.title) return this.title;
      return 'المحتوى المتغير';
    },
    skipPerPage() {
      return this.options.pageSize * (this.options.pageIndex - 1);
    },
    numberOfPages() {
      const pages = Math.ceil(this.total / this.options.pageSize) || 1;
      return pages;
    },
    permissions() {
      if (this.contentTypePermissions != undefined) {
        return this.contentTypePermissions;
      }
      return this.dynamicContentPermissions || {};
    },
    isCanRead() {
      const { isCanRead = false } = this.permissions;
      return isCanRead;
    },
    isCanCreate() {
      const { isCanCreate = false } = this.permissions;
      return isCanCreate;
    },
    isCanUpdate() {
      const { isCanUpdate = false } = this.permissions;
      return isCanUpdate;
    },
    isCanDelete() {
      const { isCanDelete = false } = this.permissions;
      return isCanDelete;
    },
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    deleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من حذف المحتوى ؟',
        confirm: () => {
          return this.$http
            .delete('app/dynamic-content/' + item.id, {})
            .then(() => {
              this.getData();
              return true;
            })
            .catch((error) => {
              const response = error.response || {};
              const { data: { error: { code, message } = {} } = {} } = response;
              if (code == 'auth401') {
                var msg = 'غير مخول : لاتملك صلاحية الحذف';
                return showSnackbarWarningMessage(msg);
              }
              showSnackbarFailMessage('حدث خطأ اثناء عملية الحذف');
              return false;
            });
        },
      });
    },
    cancelDeleteRow(item) {
      this.openConfirm({
        message: 'هل انت متأكد من استعادة المحتوى ؟',
        confirm: () => {
          return this.$http
            .post('app/dynamic-content/' + item.id + '/cancel-delete/', {})
            .then(() => {
              this.getData();
              return true;
            })
            .catch((error) => {
              showSnackbarFailMessage('حدث خطأ اثناء عملية الاستعادة');
              return false;
            });
        },
      });
    },
    onCloseDialog() {
      this.openToEdit = false;
      this.openToView = false;
      this.parentId = null;
    },
    editRow(item) {
      this.parentId = item.parentId;
      this.openToEdit = true;
      this.itemId = item.id;
    },
    setItemID(id) {
      if (id) return this.editRow({ id });
      this.itemId = null;
      this.onCloseDialog();
    },
    addChildren(id) {
      this.parentId = id;
      const { defaultDialogRef } = this.$refs;
      defaultDialogRef.openDialog();
    },
    viewRow(item) {
      // this.openToView = true;
      // this.itemId = item.id;
      const languageDtls = item?.dynamicContentLanguages[0] || {};
      const title = languageDtls.title;
      const url = '{url}-' + item.slug;
      const editorData = getDataFromJSON(languageDtls.contentJson);
      viewHTMLContent(title, url, editorData);
    },
    changeLanguage(language) {
      this.options.language = language;
    },
    changeIsDeleted(isDeleted) {
      this.options.isDeleted = isDeleted;
    },
    changeIsFeatureIsSticky({ isFeature, isSticky }) {
      this.options.isFeature = isFeature;
      this.options.isSticky = isSticky;
    },
    fetchData(params, onDone) {
      ourGetRequest = axios.CancelToken.source();

      this.$store.commit('loading/SET_SHOW', true);
      const requestConfig = {
        params,
        paramsSerializer: function (params) {
          const queryString = qs.stringify(params, { arrayFormat: 'brackets' });
          return queryString;
        },
        cancelToken: ourGetRequest.token,
      };

      this.$http
        .get('app/dynamic-content/by-filters', requestConfig)
        .then((response) => {
          const { items, totalCount } = response.data;

          const resultData = items.reduce((p, c) => {
            const language = c.dynamicContentLanguages[0];

            const categoryNames = c.categories.map(({ category }) => {
              const categoryLanguage =
                category && category.categoryLanguages[0];
              const { name = '' } = categoryLanguage || {};
              return name;
            });

            const contentTypeLanguage =
              c.contentType && c.contentType.contentTypeLanguages[0];

            const data = [
              ...p,
              { ...c, language, categoryNames, contentTypeLanguage },
            ];
            return data;
          }, []);

          onDone(resultData, totalCount);
        })
        .catch((error) => {
          if (error.message === 'cancel_req') return;
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
        })
        .finally(() => this.$store.commit('loading/SET_SHOW', false));
    },

    getData() {
      const params = {
        contentTypeId: this.contentTypeId,
        skipCount: this.skipPerPage,
        maxResultCount: this.options.pageSize,
        language: this.options.language,
        title: this.options.title,
        isDeleted: this.options.isDeleted,
        isFeature: this.options.isFeature,
        isSticky: this.options.isSticky,
        includeSchema: false,
        IsRoot: true,
      };

      this.fetchData(params, (resultData, totalCount) => {
        this.pages = resultData;
        this.total = totalCount;
        this.dataChanged++;
      });
    },
    getChildData(item) {
      if (item.children && item.children.length > 0) return;

      const params = {
        contentTypeId: this.contentTypeId,
        maxResultCount: 1000,
        language: this.options.language,
        includeSchema: false,
        parentId: item.id,
      };

      this.fetchData(params, (resultData) => {
        item.children = resultData;
      });
    },
    resetFilters() {
      const { languagesFilterRef, deletedFilterRef, featureStickyFilterRef } =
        this.$refs;
      languagesFilterRef && languagesFilterRef.reset();
      deletedFilterRef && deletedFilterRef.reset();
      featureStickyFilterRef && featureStickyFilterRef.reset();

      this.filters = { ...initFilters };
    },
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    'filters.title': {
      handler() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(
          () => Object.assign(this.options, this.filters),
          500,
        );
      },
    },
    contentTypeId() {
      if (ourGetRequest != null) ourGetRequest.cancel('cancel_req');
      this.pages = [];
      this.options = { ...initOptions };
      this.resetFilters();
    },
  },
};
</script>
