<template>
  <UploadManager
    v-model="videoModel"
    :selectedLajnguage="selectedLajnguage"
    :buttonTitle="buttonTitle"
    :buttonIcon="buttonIcon"
    :multiple="multiple"
    uploadNotes="upload video from upload video manager"
    uploadType="video"
    accept="video/mp4"
    browseFileIcon="mdi-video-plus-outline"
    title="فديو"
    gridTitle="اختر فديو"
    uploadTitle="تحميل فديو"
    selectFileToUploadMsg="اختر الفديو المراد تحميله"
    successUploadMsg="تم تحميل الفديو"
    fileNameLabel="اسم الفديو"
  >
    <!-- ----------------------------------------------------------------- -->
    <!-- filesGridPreview -->
    <!-- ----------------------------------------------------------------- -->
    <template
      #filesGridPreview="{
        files,
        selectFile,
        getSelectedFileClass,
        isSelected,
        remove,
        isLoading: fileLoading,
        editFile,
      }"
    >
      <v-col
        v-for="({ src: fileSrc, alt, id: fileId, creator }, index) in files"
        :key="index"
        class="d-flex child-flex"
        cols="12"
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <v-card
          @click="selectFile(fileSrc)"
          outlined
          elevation="0"
          class="d-flex flex-column"
          :class="`${getSelectedFileClass(fileSrc)}`"
        >
          <v-card-text
            class="video-conainer pa-0"
            :class="`grey lighten-2 ${getSelectedFileClass(fileSrc)}`"
          >
            <v-card-actions class="centerFloat">
              <v-btn
                small
                class="btnHide error darken-2"
                @click.stop="remove(fileId)"
                :disabled="fileLoading"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                small
                class="btnHide"
                @click.stop="editFile(fileId, alt, getSrc(fileSrc))"
                :disabled="fileLoading"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>

            <video :src="getSrc(fileSrc)" width="100%" height="100%" />
          </v-card-text>

          <div class="d-flex justify-space-between flex-column flex-grow-1">
            <v-card-title class="subtitle-1 pb-1"> {{ alt }} </v-card-title>
            <v-card-subtitle class="subtitle-2 pb-1 pt-0 ma-0">
              <v-chip x-small>
                <v-icon x-small class="ml-1">mdi-account</v-icon>
                {{ getUserName(creator) }}
              </v-chip>
            </v-card-subtitle>
          </div>
        </v-card>
      </v-col>
    </template>

    <!-- ----------------------------------------------------------------- -->
    <!-- uploadfilePreview -->
    <!-- ----------------------------------------------------------------- -->
    <template #uploadfilePreview="{ browseFile, fileSrc }">
      <v-fade-transition mode="out-in">
        <div class="file-container" @click="browseFile">
          <video
            v-if="fileSrc"
            height="100%"
            width="100%"
            class="grey lighten-1"
            :src="fileSrc"
          />
          <v-row
            v-else
            class="grey fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-icon color="white">mdi-video</v-icon>
          </v-row>
        </div>
      </v-fade-transition>
    </template>

    <!-- ----------------------------------------------------------------- -->
    <!-- selectedFiles -->
    <!-- ----------------------------------------------------------------- -->
    <template #selectedFiles="{ files, selectFile }">
      <v-subheader>الفديو المختار</v-subheader>
      <v-sheet class="mx-auto" height="120px">
        <v-slide-group multiple show-arrows :center-active="true">
          <v-slide-item
            v-for="(fileSrc, index) in files"
            :key="index"
            v-slot="{ active, toggle }"
          >
            <div class="selected-file-container">
              <div
                class="close d-flex justify-center"
                @click="selectFile(fileSrc)"
              >
                <v-icon color="white">mdi-close</v-icon>
              </div>

              <video :src="getSrc(fileSrc)" width="100%" height="100%" />
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </template>
    <!-- ----------------------------------------------------------------- -->
  </UploadManager>
</template>

<script>
import { getVideoURL } from '@/helper/cmsPaths';
import UploadManager from './UploadManager.vue';
export default {
  props: ['value', 'selectedLajnguage', 'text', 'icon', 'multiple'],
  components: { UploadManager },
  computed: {
    videoModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    buttonTitle() {
      return this.text || 'اضافة/الغاء فديو';
    },
    buttonIcon() {
      return this.icon || 'mdi-video';
    },
  },
  methods: {
    getSrc(src) {
      return getVideoURL(src);
    },
    getUserName(creator) {
      return creator?.userName || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.video-conainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .centerFloat {
    position: absolute;
    z-index: 1;
  }

  .btnHide {
    opacity: 0;
  }

  &:hover {
    .btnHide {
      opacity: 1;
    }
  }
}

.file-container {
  height: 210px;
  border: 1px solid #999 !important;
  padding: 2px;
}

.selected-file-container {
  position: relative;
  margin: 8px;
  height: 95px;

  img {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    object-fit: cover;
  }

  .close {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    opacity: 0;
    z-index: 1;
  }

  &:hover .close {
    opacity: 1;
    cursor: pointer;
  }
}
</style>
