<template>
  <div :style="contentStyle">
    <v-btn
      fab
      absolute
      top
      left
      small
      color="pink"
      class="mr-1 white--text"
      :loading="contentStyle.borderColor == 'red'"
      @click="removeSlider(id)"
      v-if="canDeleteField"
    >
      <v-icon color="#fff">mdi-delete</v-icon>
    </v-btn>
    <div class="my-5 px-5">
      <h2>محرر متعدد</h2>
    </div>

    <v-row>
      <v-col cols="12">
        <hr style="width: 170px" />
      </v-col>
    </v-row>

    <v-row v-if="!render">
      <v-col cols="12">
        <v-text-field
          v-model="field.slug"
          label="slug"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" multiple class="my-5">
      <v-expansion-panel
        v-for="(content, idx) in contents"
        :key="content.data.id"
      >
        <v-expansion-panel-header :id="content.data.id">
          <div>
            <span>المحرر رقم {{ idx + 1 }} </span>
            |
            <b>{{ content.data.title?.[selectedLanguage] }}</b>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <v-row>
            <v-col cols="12">
              <component
                v-if="isEditor(idx)"
                :is="content.data.component"
                :data="content.data"
                :ref="idx"
                @remove="removeMultiEditorCard"
                :selectedLanguage="selectedLanguage"
                :hideDeleteField="!canDeleteMultiEditorCard"
                :render="render"
              />
              <span v-else :id="content.data.id" :ref="`${prefix}${idx}`">
                {{ content.data.id }}
              </span>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row v-if="canAddMultiEditorCard">
      <v-col>
        <v-btn
          color="primary"
          @click="addMultiEditorCard"
          :loading="loadingAdd"
          :disabled="loading"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { makeid } from '@/utls/jsCommon';
import MultiEditorCard from './MultiEditorCard.vue';

export default {
  props: {
    selectedLanguage: {},
    hideDeleteField: {},
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    render: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MultiEditorCard,
  },
  data() {
    return {
      panel: [],
      openedPanel: [],
      imageIds: 0,
      contents: [],
      loadingAdd: false,
      contentStyle: {
        border: '2px solid',
        borderColor: 'var(--secondary-color)',
        borderRadius: '4px',
        padding: '10px 20px 30px',
        marginBottom: '10px',
        width: '100%',
        position: 'relative',
      },
      field: {
        slug: '',
        contents: [
          {
            title: {
              // multi languages { ar: value, en: value }
            },
            content: {
              // multi languages { ar: value, en: value }
            },
          },
        ],
      },
    };
  },
  computed: {
    prefix() {
      return 'raw_';
    },
    id() {
      const { id } = this.data;
      return id;
    },
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
    canAddMultiEditorCard() {
      return true;
    },
    canDeleteMultiEditorCard() {
      return true;
    },
  },
  methods: {
    getDetails() {
      var details = Object.keys(this.$refs).map((fieldRefId) => {
        if (!fieldRefId.startsWith(this.prefix)) return {};

        var id = fieldRefId.replaceAll(this.prefix, '');

        var isEditorComponent = this.isEditor(parseInt(id));

        const fieldRef = this.$refs[isEditorComponent ? id : fieldRefId];

        if (!fieldRef || !fieldRef.length) return {};
        const refDtls = fieldRef[0];

        // editor
        if (isEditorComponent) return { data: refDtls._data, $v: refDtls.$v };

        // get raw data (not edited)
        var spanElementDataId = parseInt(refDtls.id);
        var data = this.getDataById(spanElementDataId);

        if (!data) return {};

        return { data: { field: data }, $v: null };
      });

      return details;
    },
    isEditor(idx) {
      return this.openedPanel.includes(idx);
    },
    getDataById(id) {
      const { data } = this.contents.find((x) => x.data.id == id) || {};
      return data;
    },
    removeSlider(id) {
      this.contentStyle.borderColor = 'red';
      setTimeout(() => this.$emit('remove', id), 600);
    },
    removeMultiEditorCard(id) {
      const index = this.contents.findIndex((f) => f.data.id === id);
      index != -1 && this.contents.splice(index, 1);
    },
    withLoading(cb) {
      this.loadingAdd = true;
      setTimeout(() => {
        this.loadingAdd = false;
        cb();
      }, 500);
    },
    addMultiEditorCard(loading = true) {
      const add = () => {
        const data = { id: this.generatedId(), component: 'MultiEditorCard' };

        this.contents.push({ data });

        setTimeout(() => {
          const item = document.getElementById(data.id);
          this.panel.push(this.contents.length - 1);
          setTimeout(() => item.scrollIntoView({ behavior: 'smooth' }), 400);
        }, 100);
      };
      if (loading) this.withLoading(add);
      else add();
    },
    generatedId() {
      return ++this.imageIds;
    },
    initMultiEditorCard({ empty, contents }) {
      if (empty) this.addMultiEditorCard(false);
      if (!contents || contents.length == 0) return;
      this.contents = contents.map((content) => ({ data: { ...content } }));
      const maxId = Math.max(...contents.map((f) => f.id));
      this.imageIds = maxId;
    },
    initField() {
      if (this.data) this.field = this.data;

      const { contents } = this.data || {};
      if (contents) this.initMultiEditorCard({ contents });
      else this.initMultiEditorCard({ empty: true });

      if (!this.field.slug) this.field.slug = makeid(15);
    },
    changeCardBorderColor() {
      const isDark = this.$store.state.theme.dark;

      let bcolor = 'var(--primary-color)';
      if (this.render && isDark) bcolor = '#333';
      else if (this.render && !isDark) bcolor = '#eee';

      this.contentStyle.borderColor = bcolor;
    },
  },
  created() {
    this.initField();
  },
  mounted() {
    this.changeCardBorderColor();
  },
  watch: {
    panel(val) {
      this.openedPanel = [...new Set([...this.openedPanel, ...val])];
    },
  },
};
</script>

<style lang="scss" scoped></style>
