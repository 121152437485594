<template>
  <v-card elevation="0" outlined>
    <!-- TABS HEADER -->
    <v-toolbar flat :style="getTabHeaderStyle">
      <v-tabs v-model="tab">
        <v-tabs-slider color="white darken-1"></v-tabs-slider>
        <v-tab
          v-for="lang in languagesKeysValue"
          :key="lang.key"
          class="font-weight-black"
          active-class="primary lighten-1 white--text"
        >
          {{ lang.value }}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <!-- TABS CONTENT -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="lang in languagesKeysValue" :key="lang.key">
        <v-card flat :min-height="minHeight">
          <v-container fluid>
            <template v-if="render">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1" v-text="name" />
                  <v-list-item-subtitle v-text="description" />
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-row v-else>
              <v-col cols="12">
                <v-text-field
                  v-model="fieldLanguages.name[`${lang.key}`]"
                  :counter="50"
                  :label="'الاسم بالـ ' + lang.value"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="fieldLanguages.description[`${lang.key}`]"
                  :counter="200"
                  :label="'التفاصيل بالـ ' + lang.value"
                  outlined
                  dense
                />
              </v-col>
            </v-row>

            <v-row v-if="render">
              <v-col cols="12">
                <Editor
                  :id="`editor${value.id + lang.key}`"
                  :contentMinHeight="minHeight"
                  v-model="fieldLanguages[`${lang.key}`]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import BaseEditor from '@/components/BaseEditor.vue';
import { languagesKeys, languagesKeysValue } from '@/config/config';

const buildLanguageKeysWithEmptyValue = (selected) => {
  const langKeys = { ...languagesKeys() };
  for (var key in langKeys) {
    // for all fieldLanguages
    // or
    // for only selectedLanguage
    if (selected && selected != key) {
      delete langKeys[key];
    } else if (key) langKeys[key] = '';
  }
  return langKeys;
};

const getLanguagesKeysValue = (selected) => {
  if (!selected) return languagesKeysValue();
  return languagesKeysValue().filter((lang) => lang.key == selected);
};

export default {
  props: ['value', 'selectedLanguage', 'render'],
  components: {
    Editor: BaseEditor,
  },
  data() {
    return {
      tab: null,
      languagesKeysValue: getLanguagesKeysValue(this.selectedLanguage),
      fieldLanguages: {
        ...buildLanguageKeysWithEmptyValue(this.selectedLanguage), // { ar:{ }, en:{ } }
        name: { ...buildLanguageKeysWithEmptyValue(this.selectedLanguage) }, // { ar:{ }, en:{ } }
        description: {
          ...buildLanguageKeysWithEmptyValue(this.selectedLanguage),
        },
      },
    };
  },
  computed: {
    getTabHeaderStyle() {
      if (this.selectedLanguage) return 'display: none;';
      else return '';
    },
    minHeight() {
      if (this.render) return '10cm';
      return '';
    },
    description() {
      return this.fieldLanguages.description[this.selectedLanguage];
    },
    name() {
      return this.fieldLanguages.name[this.selectedLanguage];
    },
  },
  watch: {
    fieldLanguages: {
      handler(value) {
        const updatedValue = { ...this.value, ...value };

        if (this.selectedLanguage) {
          for (var key in { ...languagesKeys() }) {
            // for all fieldLanguages
            // or
            // for only selectedLanguage
            if (this.selectedLanguage != key) delete updatedValue[key];
          }
        }

        this.$emit('input', updatedValue);
      },
      deep: true,
    },
  },
  created() {
    for (const k in this.fieldLanguages) {
      const prop = this.value[k];
      if (!prop) continue;

      // is key has keys { ar: '', en: '', .. }
      if (['name', 'description'].indexOf(k) != -1) {
        for (const languageCode in this.fieldLanguages[k]) {
          this.fieldLanguages[k][languageCode] = prop[languageCode];
        }
      } else {
        // is key has only value
        this.fieldLanguages[k] = prop;
      }
    }
  },
};
</script>
