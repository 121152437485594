<template>
  <div :style="fileStyle">
    <v-btn
      fab
      absolute
      top
      left
      small
      color="pink"
      class="mr-1 white--text"
      :loading="fileStyle.borderColor == 'red'"
      @click="removeSlider(id)"
      v-if="!render && canDeleteField"
    >
      <v-icon color="#fff">mdi-delete</v-icon>
    </v-btn>

    <div class="my-5 px-5">
      <h2>جدول</h2>
    </div>

    <v-row>
      <v-col cols="12">
        <hr style="width: 120px" />
      </v-col>

      <v-col cols="12">
        <TableLanguages
          v-model="field"
          :selectedLanguage="selectedLanguage"
          :render="render"
        />
      </v-col>

      <v-col cols="12" v-if="!render">
        <v-text-field v-model="field.slug" label="slug" outlined />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { makeid } from '@/utls/jsCommon';
import TableLanguages from './TableLanguages.vue';

export default {
  props: {
    selectedLanguage: {},
    hideDeleteField: {},
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    render: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TableLanguages,
  },
  data() {
    return {
      fileStyle: {
        border: '2px solid',
        borderColor: 'var(--secondary-color)',
        borderRadius: '4px',
        padding: '10px 20px 30px',
        marginBottom: '10px',
        width: '100%',
        position: 'relative',
      },
      field: {
        slug: '',
        header: {},
        rows: {},
      },
    };
  },
  computed: {
    id() {
      const { id } = this.data;
      return id;
    },
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
    canAddTableCard() {
      return true;
    },
    canDeleteTableCard() {
      return true;
    },
  },
  methods: {
    getDetails() {
      return this.field;
    },
    removeSlider(id) {
      this.fileStyle.borderColor = 'red';
      setTimeout(() => this.$emit('remove', id), 600);
    },
    initTableCard({ empty, rows }) {
      if (!rows || rows.length == 0) return;
    },
    initField() {
      if (this.data) this.field = this.data;

      const { rows } = this.data || {};
      if (rows) this.initTableCard({ rows });

      if (!this.field.slug) this.field.slug = makeid(15);
    },
    changeCardBorderColor() {
      const isDark = this.$store.state.theme.dark;

      let bcolor = 'var(--primary-color)';
      if (this.render && isDark) bcolor = '#333';
      else if (this.render && !isDark) bcolor = '#eee';

      this.fileStyle.borderColor = bcolor;
    },
  },
  created() {
    this.initField();
  },
  mounted() {
    this.changeCardBorderColor();
  },
};
</script>

<style lang="scss" scoped></style>
