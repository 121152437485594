<template>
  <div class="d-flex flex-row">
    <v-chip-group active-class="primary" multiple v-model="selectedOption">
      <v-chip filter label :value="isFeature"> مميز </v-chip>
      <v-chip filter label :value="isSticky"> مثبت </v-chip>
    </v-chip-group>
    <v-chip-group active-class="primary darken-2" v-model="selectedOption">
      <v-chip filter label :value="[]"> الكل </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
const initData = {
  selectedOption: [],
  isFeature: 1,
  isSticky: 2,
};

export default {
  data() {
    return { ...initData };
  },
  methods: {
    isTrue(value) {
      const selectedOption = this.selectedOption;
      if (Array.isArray(selectedOption)) {
        return selectedOption.includes(value) || null;
      }
      return selectedOption == value || null;
    },
    reset() {
      Object.assign(this.$data, initData);
    },
  },
  watch: {
    selectedOption() {
      const isFeature = this.isTrue(1);
      const isSticky = this.isTrue(2);
      this.$emit('change', { isFeature, isSticky });
    },
  },
};
</script>

<style lang="scss" scoped></style>
