<template>
  <div class="mr-5">
    <v-badge
      bordered
      overlap
      transition="slide-x-transition"
      color="green"
      :content="content"
      :left="!isInline"
      :inline="isInline"
    >
      <v-hover v-model="hover">
        <v-icon large> mdi-eye-circle </v-icon>
      </v-hover>
    </v-badge>
  </div>
</template>

<script>
import { nFormatter } from '@/helper/commonjs';
export default {
  props: ['count'],
  data: () => ({
    hover: false,
  }),
  computed: {
    content() {
      if (this.count == undefined) return 0;
      if (this.hover) return this.count || '0';
      return nFormatter(this.count, 1) || 0;
    },
    isLargeNumber() {
      return this.count.toString().length >= 4;
    },
    isInline() {
      return this.hover && this.isLargeNumber;
    },
  },
};
</script>

<style lang="scss" scoped></style>
