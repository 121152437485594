<template>
  <v-chip-group active-class="red white--text" v-model="filterValue">
    <v-chip filter label :value="true"> محذوف </v-chip>
  </v-chip-group>
</template>

<script>
const initData = {
  filterValue: false,
};
export default {
  props: ['cols'],
  data: () => ({
    ...initData,
  }),
  methods: {
    reset() {
      Object.assign(this.$data, initData);
    },
  },
  watch: {
    filterValue(newValue) {
      this.$emit('change', !!newValue);
    },
  },
};
</script>

<style lang="scss" scoped></style>
