<template>
  <form class="fill-height">
    <v-container fluid class="fill-height">
      <v-row class="d-flex flex-row-reverse fill-height">
        <v-col
          cols="12"
          md="3"
          class="flex-grow-1 fill-height"
          :class="{ 'overflow-y-auto': $vuetify.breakpoint.name != 'sm' }"
        >
          <v-row>
            <v-col cols="12">
              <v-row class="my-1 justify-start align-center">
                <ViewCount :count="viewCount" />

                <v-checkbox
                  v-model="isSticky"
                  color="primary"
                  label="مثبت"
                  class="shrink mr-2"
                  :on-icon="'mdi-pin'"
                  :off-icon="'mdi-pin-off'"
                >
                </v-checkbox>
                <v-checkbox
                  :on-icon="'mdi-star'"
                  :off-icon="'mdi-star-outline'"
                  v-model="isFeature"
                  color="orange darken-3"
                  label="مميز"
                  class="shrink mr-2"
                ></v-checkbox>
              </v-row>
            </v-col>
            <v-spacer />
            <v-col cols="10" sm="8" v-if="itemId">
              <AuditLog
                :url="`app/dynamic-content/${itemId}/changes`"
                class="my-1 justify-end"
                :creationTime="creationTime"
                creationUsername="admin"
                :lastModificationTime="lastModificationTime"
                lastModificationUsername="admin"
                :colsName="{
                  PublishDate: 'تاريخ النشر',
                  ExpiryDate: 'تاريخ الانتهاء',
                  ContentStatusId: 'الحالة',
                  ContentTypeId: 'الهيكلية',
                  IsFeature: 'مميز',
                  IsSticky: 'مثبت',
                  Slug: 'slug',
                  ContentJSON: 'المحتوى',
                  ImagesJSON: 'الصور',
                  Title: 'العنوان',
                }"
                :renderSlot="true"
              >
                <template #originalValue="{ item, value }">
                  <span>{{
                    getLogValue(item, value, { isOriginal: true })
                  }}</span>
                </template>
                <template #newValue="{ item, value }">
                  <span>{{
                    getLogValue(item, value, { isOriginal: false })
                  }}</span>
                </template>
              </AuditLog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <h1>بيانات اساسية</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <SlugBase v-model="slug" :foundedSlug="foundedSlug" />
            </v-col>
            <v-col cols="12">
              <MultiSelectCategory
                v-model="categories"
                :error-messages="categoriesErrors"
                :contentTypeId="contentTypeId"
              />
            </v-col>

            <v-col cols="12">
              <Autocomplete
                :url="contentStatusAutoCompleteUrl"
                :params="{ language: 'ar' }"
                displayProp="text"
                label="الحالة"
                placeholder="ابحث عن حالة"
                :value="modifyStatusAddText(contentStatus)"
                @onChange="(value) => (contentStatus = value)"
                renderSlot
                :error-messages="contentStatusErrors"
                :modifyItems="
                  (items) => {
                    if (!items) return [];
                    const entries = items.reduce((p, status) => {
                      return [...p, this.modifyStatusAddText(status)];
                    }, []);
                    return entries;
                  }
                "
              >
                <template #render="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.language.name" />
                    <v-list-item-subtitle v-html="item.slug" />
                  </v-list-item-content>
                </template>
              </Autocomplete>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="7">
                  <DateBase
                    v-model="publishDate"
                    placeholder="تاريخ النشر"
                    label="تاريخ النشر"
                    outlined
                  />
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    hide-details="auto"
                    dense
                    v-model="time"
                    v-mask="'##:##'"
                    :error-messages="timeErrors"
                    label="الوقت"
                    @input="$v.time.$touch()"
                    @blur="$v.time.$touch()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <DateBase
                v-model="expiryDate"
                placeholder="تاريخ الانتهاء"
                label="تاريخ الانتهاء"
                outlined
                :empty="true"
                :clearable="true"
              />
            </v-col>
            <v-col cols="12" v-if="parentTitle">
              <div>الاب : <b>{{ parentTitle }}</b></div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="flex-grow-1 fill-height"
          :class="{ 'overflow-y-auto': $vuetify.breakpoint.name != 'sm' }"
        >
          <v-card elevation="0" outlined>
            <v-container fluid>
              <v-card-text>
                <v-row class="align-center">
                  <v-menu transition="scale-transition" origin="center center">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        :loading="loadingAddSchema"
                      >
                        <v-icon>mdi-menu</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="lang in languages"
                        :key="lang.key"
                        @click="addRemoveLanauge(lang.key)"
                        :class="getLanguagesClass(lang.key)"
                      >
                        <v-list-item-title>
                          <v-icon :class="getLanguagesClass(lang.key)">
                            {{
                              isLanguageAdded(lang.key)
                                ? 'mdi-close'
                                : 'mdi-plus'
                            }}
                          </v-icon>
                          {{ lang.value }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <h1 class="mx-1">اللغة</h1>
                </v-row>
              </v-card-text>
            </v-container>
          </v-card>

          <v-card elevation="1">
            <!-- TABS HEADER -->
            <v-tabs
              show-arrows
              background-color="indigo"
              dark
              v-model="tab"
              align-with-title
              fixed-tabs
              class="mb-4"
              height="45px"
            >
              <v-tabs-slider color="white darken-1"></v-tabs-slider>
              <v-tab
                v-for="lang in addedLanguages"
                :key="lang.key"
                class="white--text font-weight-black"
                active-class="indigo lighten-2"
              >
                {{ lang.value }}
              </v-tab>
            </v-tabs>

            <!-- TABS CONTENT -->
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="lang in addedLanguages" :key="lang.key">
                <v-card flat :style="fieldContainerStyle(lang.key)">
                  <v-container fluid class="pb-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="formLanguage[`${lang.key}`][`title`]"
                          :counter="100"
                          :label="'العنوان بالـ ' + lang.value"
                          outlined
                          hide-details="auto"
                          dense
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="
                                formLanguage[`${lang.key}`][`description`]
                              "
                              :label="'الوصف بالـ ' + lang.value"
                              outlined
                              hide-details="auto"
                              dense
                              :counter="200"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <ContentTags
                              :label="'المفاتيح بالـ ' + lang.value"
                              :id="formLanguage[`${lang.key}`][`id`]"
                              :selectedLanguage="lang.key"
                              v-model="formLanguage[`${lang.key}`][`tags`]"
                            />
                          </v-col>
                          <v-col cols="6">
                            <MultiSelect
                              v-model="
                                formLanguage[`${lang.key}`][`searchKeys`]
                              "
                              :label="'كلمات مفتاحية للبحث بالـ ' + lang.value"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6" v-show="false">
                        <MultiSelect
                          v-model="formLanguage[`${lang.key}`][`keyword`]"
                          :label="'كلمات مفتاحية بالـ ' + lang.value"
                        />
                      </v-col>

                      <v-col cols="12">
                        <v-card elevation="0" outlined class="pa-4">
                          <v-row>
                            <v-col
                              cols="6"
                              class="d-flex flex-column justify-space-between"
                            >
                              <v-subheader>
                                الصور المحملة بالـ {{ lang.value }}
                              </v-subheader>
                              <v-card-title class="pb-1">
                                <UploadImage
                                  v-model="
                                    formLanguage[`${lang.key}`][`images`]
                                  "
                                  :selectedLajnguage="lang.key"
                                />
                              </v-card-title>
                            </v-col>
                            <v-col cols="6">
                              <!-- <v-sheet class="mx-auto" height="120px">
                            <v-slide-group
                              multiple
                              show-arrows
                              :center-active="true"
                            >
                              <v-slide-item
                                v-for="(imageSrc, index) in getImages(
                                  formLanguage[`${lang.key}`][`images`],
                                )"
                                :key="index"
                              >
                                <div class="image-container">
                                  <img
                                    :src="getImageSrc(imageSrc, 'sm')"
                                    class="ma-2"
                                  />
                                </div>
                              </v-slide-item>
                            </v-slide-group>
                          </v-sheet> -->

                              <!-- Temporary adjustment to the way the image is displayed -->
                              <v-col cols="12" dark class="background">
                                <div
                                  style="
                                    max-height: 200px;
                                    max-width: 1000px;
                                    margin: auto;
                                    border-radius: 4px;
                                    overflow: hidden;
                                    aspect-ratio: 16/9;
                                  "
                                >
                                  <v-img
                                    v-if="formLanguage[`${lang.key}`][`images`]"
                                    :src="
                                      getImageSrc(
                                        formLanguage[`${lang.key}`][
                                          `images`
                                        ][0],
                                        'lg',
                                      )
                                    "
                                    class="fill-height ma-0"
                                    contain
                                  />
                                  <v-row
                                    v-else
                                    class="background fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-icon large>mdi-image</v-icon>
                                  </v-row>
                                </div>
                              </v-col>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>

                      <v-col cols="12">
                        <FieldCards
                          :title="'هيكل المحتوى بالـ ' + lang.value"
                          :ref="`fieldContoller_${lang.key}`"
                          :schema="formLanguage[`${lang.key}`][`contentJSON`]"
                          :selectedLanguage="lang.key"
                          :withSchemaTypes="true"
                          :render="true"
                          :grouped="true"
                          :canRemove="canRemoveGroup"
                          @removeSchema="removeAllSchema"
                          @loadingAdd="onLoadingAddSchema"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <!-- 
            ------------------------------------
              SchemaTypes
            ------------------------------------
            -->
            <v-container fluid>
              <v-row>
                <v-col>
                  <SchemaTypes
                    :loadingAdd="loadingAddSchema"
                    :type="contentTypeId"
                    :buildSchemaTypeId="schemaTypeId"
                    selectedLanguage="ar"
                    @pushed="pushedSchema"
                    :pushDirect="isNew"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </form>
</template>

<script>
import FieldCards from '@/components/Fields/FieldCards.vue';
import Autocomplete from '@/components/Autocomplete.vue';

import {
  languagesKeys,
  defaultLanguagesKeys,
  languagesKeysValue,
  defaultLanguagesKeysValue,
} from '@/config/config';

import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';

import DateBase from '@/components/DateBase.vue';
import {
  defaultDateFormat,
  makeid,
  createDateTimeFromString,
  toValidDateTime,
} from '@/utls/jsCommon';
import MultiSelect from '@/components/MultiSelect.vue';
import UploadImage from '@/components/UploadManager/UploadImage.vue';
import SchemaTypes from '@/components/SchemaType/SchemaTypes.vue';
import { mapActions } from 'vuex';
import MultiSelectCategory from '@/components/MultiSelectCategory.vue';
import ContentTags from '@/components/ContentTags.vue';
import { TagUtls } from '@/model/tags/tagUtls';
import { getImageURL } from '@/helper/cmsPaths';
import AuditLog from '@/components/auditLog/AuditLog.vue';
import SlugBase from '@/components/SlugBase.vue';
import ViewCount from '../components/ViewCount.vue';
import { getErrorDetails } from '@/helper/commonjs';

function getCurrentTime() {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

const timeRegex = (param) => (value) => {
  if (!param) return true; // pass : no need to validate
  if (!value) return true; // pass : no need to validate
  const regex = /^([0-1]?[0-9]|2[0-4]):[0-5][0-9]$/;
  return regex.test(value);
};

const initData = () => ({
  // for languages tab
  tab: null,
  // for languages
  languages: languagesKeysValue(),
  addedLanguages: defaultLanguagesKeysValue(),
  formLanguage: { ...languagesKeys() }, // { ar:{ }, en:{ } }
  // for main info
  slug: '',
  categories: [],
  contentStatusId: null,
  contentStatus: null,
  contentTypeId: null,
  contentType: null,
  schemaResults: { invalid: false },
  isFeature: false,
  isSticky: false,
  publishDate: defaultDateFormat(),
  expiryDate: null,
  creationTime: '',
  lastModificationTime: '',
  loadingAddSchema: false,
  foundedSlug: '',
  viewCount: 0,
  time: getCurrentTime(),
  parent: null,
});

let schemaType = null;

export default {
  props: [
    'submitWithClose',
    'submitWithNew',
    'submitWithoutClose',
    'setItemID',
    'afterSubmit',
    'closeDialog',
    'isNew',
    'isView',
    'isEdit',
    'itemId',
    'schemaTypeId',
    'parentId',
  ],
  components: {
    Autocomplete,
    FieldCards,
    DateBase,
    MultiSelect,
    UploadImage,
    SchemaTypes,
    MultiSelectCategory,
    ContentTags,
    AuditLog,
    SlugBase,
    ViewCount,
  },
  mixins: [validationMixin],
  validations: {
    categories: { required },
    contentStatus: { required },
    time: { timeRegex: timeRegex(true) },
  },
  data: () => ({ ...initData() }),
  async created() {
    if (this.isEdit || this.isView) {
      const responseData = await this.getById(this.itemId);
      if (responseData == null) return; // there is no data - error

      for (const column in this.$data) {
        const value = responseData[column];
        if (value == undefined) continue;
        this.$data[column] = value;
      }

      this.time = this.getTimeFromDate(this.publishDate);

      const { languages } = this;
      for (const { key: langKey } of languages) {
        // { language: 'ar', .. }, { language: 'en', .. }
        const responseLanguages = responseData.dynamicContentLanguages;
        const languageDetail = responseLanguages.find(
          (x) => x.language == langKey,
        );
        if (languageDetail == undefined) continue;
        this.formLanguage[langKey] = languageDetail;

        this.setLanguagesKeysValue(langKey);
      }
    } else {
      this.setAllContentJSON('');
      this.slug = makeid(15);
    }
  },
  computed: {
    parentTitle() {
      if (!this.parent || !this.parent.dynamicContentLanguages || !this.parent.dynamicContentLanguages.length) return null;
      return this.parent.dynamicContentLanguages[0].title;
    },
    contentStatusAutoCompleteUrl() {
      if (!this.contentTypeId) return '';
      return 'app/content-status/granted/' + this.contentTypeId;
    },
    canRemoveGroup() {
      return !this.schemaTypeId;
    },
    remainLanguage() {
      const allLanguage = this.languages;
      const addedLannauges = this.addedLanguages;

      const remain = allLanguage.filter(({ key }) => {
        const addedLanguage = addedLannauges.find((y) => y.key == key);
        return !addedLanguage;
      });

      return remain;
    },
    // validation
    // ----------------
    categoriesErrors() {
      const errors = [];
      if (!this.$v.categories.$dirty) return errors;
      !this.$v.categories.required && errors.push('الصنف مطلوب');
      return errors;
    },
    contentStatusErrors() {
      const errors = [];
      if (!this.$v.contentStatus.$dirty) return errors;
      !this.$v.contentStatus.required && errors.push('الحقل مطلوب');
      return errors;
    },
    timeErrors() {
      const errors = [];
      if (!this.$v.time.$dirty) return errors;
      !this.$v.time.timeRegex && errors.push('الوقت غير صحيح');
      return errors;
    },
    inputLanguages() {
      const languages = this.addedLanguages.reduce((p, v) => {
        const { key, value } = v;
        const dtls = this.formLanguage[key];

        const isEmptyValues = Object.values(dtls).every((x) => !x);
        if (isEmptyValues) return p;

        const {
          title,
          description,
          keyword,
          searchKeys,
          contentJSON,
          images,
          tags,
        } = dtls;

        // when ref no render in dom return null so we must use contentJSON loaded from db
        const schemaResult = this.schemaResults[key];
        const { schema: buildedSchema, contentTypeId } = schemaResult || {};
        const content = buildedSchema || contentJSON;

        // get content tags
        const contentTags = TagUtls.getContentTags(tags);

        return [
          ...p,
          {
            language: key,
            title,
            description,
            searchKeys,
            keyword,
            contentJSON: content,
            contentTypeId,
            images,
            tags: contentTags,
          },
        ];
      }, []);
      return languages;
    },
  },
  methods: {
    ...mapActions('confirm', ['openConfirm']),
    getTimeFromDate(date) {
      // Assuming your date is in the format 'YYYY-MM-DDTHH:mm:ss'
      var dateObject = new Date(date);
      // Extracting only the time in the format '13:31'
      var timeFormat = dateObject.toLocaleTimeString('en-US', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      });
      return timeFormat;
    },
    getLogValue(item, value, { isOriginal }) {
      try {
        if (item.propertyName == 'ContentJSON') {
          return this.getLogFieldValue(true, isOriginal, 'محتوى');
        }
        if (item.propertyName == 'ImagesJSON') {
          const images = JSON.parse(JSON.parse(value));
          const imagesCount = images.length;
          const isThereValue = imagesCount > 0;
          return this.getLogFieldValue(
            isThereValue,
            isOriginal,
            imagesCount + ' صورة',
          );
        }
        return value;
      } catch (error) {
        return '';
      }
    },
    getLogFieldValue(isThereValue, isOriginal, label) {
      if (isOriginal) return isThereValue ? label : '';
      return isThereValue ? 'تغيير لـ ' + label : '';
    },
    // getDifValue(originalValue, newValue, { isOriginal }) {
    //   return isOriginal ? originalValue : newValue;
    //   // originalValue.reduce((p, v) => {
    //   //   const newVal = newValue.find((x) => x.slug == v.slug);
    //   //   if(!newVal) return p;
    //   // }, []);
    // },

    // correctJSONAndGetFields(value) {
    //   try {
    //     const lastIndx = value.lastIndexOf('}');
    //     const newJson = value.substr(0, lastIndx + 1) + ']"';
    //     const fileds = JSON.parse(JSON.parse(newJson));
    //     return fileds;
    //   } catch (error) {
    //     return [];
    //   }
    // },
    modifyStatusAddText(status) {
      if (!status) return status;
      const { contentStatusLanguages, slug } = status;
      const language = contentStatusLanguages[0] || {};
      const text = language.name || slug;
      return { ...status, language, text };
    },
    addRemoveLanauge(key) {
      if (!this.isLanguageAdded(key)) this.addLanguage(key);
      else this.removeLanguage(key);
    },
    addLanguage(key) {
      this.setLanguagesKeysValue(key);
      const { schema = '' } = this.contentType || {};
      this.setContentJSON(key, schema);
    },
    setLanguagesKeysValue(key) {
      const allLanguage = this.languages;
      const language = allLanguage.find((x) => x.key == key);
      const isThere = this.addedLanguages.find((x) => x.key == language.key);
      if (!isThere) this.addedLanguages.push(language);
    },
    removeLanguage(key) {
      const allLanguage = this.languages;
      const language = allLanguage.find((x) => x.key == key);

      this.openConfirm({
        color: 'primary',
        title: 'الغاء اللغة ' + language.value,
        message: 'هل انت متأكد من الغاء اللغة ؟',
        subMessage: 'سوف يتم فقد جميع القيم المدخلة !',
        confirm: () => {
          const addedLannauges = this.addedLanguages;
          const addedLanguageIdx = addedLannauges.findIndex(
            (x) => x.key == key,
          );
          addedLannauges.splice(addedLanguageIdx, 1);
          this.formLanguage[key] = {};
          return true;
        },
      });
    },
    isLanguageAdded(key) {
      const addedLannauges = this.addedLanguages;
      const addedLanguage = addedLannauges.find((x) => x.key == key);
      return addedLanguage;
    },
    getLanguagesClass(key) {
      if (!this.isLanguageAdded(key)) return;
      return 'primary lighten-2 white--text';
    },
    onLoadingAddSchema(loading) {
      this.loadingAddSchema = loading;
    },
    pushedSchema(type) {
      schemaType = type;

      const {
        id: contentTypeId,
        isValid,
        schema,
        language: { name: schemaName },
      } = type;

      if (!isValid) return;

      if (this.contentTypeId != null && this.contentTypeId != contentTypeId) {
        this.openConfirm({
          color: 'green',
          title: 'تبديل الهيكلية',
          message: 'هل انت متأكد من تبديل الهيكلية ؟',
          subMessage: 'سوف يتم فقد جميع القيم المدخلة !',
          confirm: () => {
            this.changeSchema({ type, contentTypeId, schema });
            return true;
          },
        });
      } else {
        this.changeSchema({ type, contentTypeId, schema });
      }
    },
    changeSchema({ type, schema, contentTypeId }) {
      this.setAllContentJSON(schema);
      this.contentTypeId = contentTypeId || this.schemaTypeId;
      this.contentType = type;
    },
    setAllContentJSON(schema) {
      this.addedLanguages.forEach(({ key }) => {
        this.setContentJSON(key, schema);
      });
    },
    setContentJSON(key, schema) {
      if (!this.formLanguage[`${key}`]) this.formLanguage[`${key}`] = {};
      this.$set(this.formLanguage[`${key}`], 'contentJSON', schema);
    },
    removeAllSchema() {
      this.changeSchema({ schema: '', contentTypeId: null });
    },
    getImages(images) {
      return images || [];
    },
    getImageSrc(name, size) {
      return getImageURL(name, size);
    },
    isLTR(languageKey) {
      const index = ['en'].findIndex((x) => x == languageKey);
      return index != -1;
    },
    fieldContainerStyle(languageKey) {
      const style = {};
      // if (this.isLTR(languageKey)) style['direction'] = 'ltr';
      return style;
    },
    doSave(postData) {
      const config = {
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      };
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .post('app/dynamic-content', postData, config)
        .then(async (response) => {
          this.setItemID(response.data.id);
          this.creationTime = response.data.creationTime;
          this.lastModificationTime = response.data.lastModificationTime;
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    doUpdate(postData) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .put('app/dynamic-content/' + this.itemId, { ...postData })
        .then(async (response) => {
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
          return true;
        })
        .catch(this.errorSaveHabdler)
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    errorSaveHabdler(error) {
      const { code, message } = getErrorDetails(error);

      if (code == 'Slug_Found') {
        this.foundedSlug = this.slug;
        return showSnackbarFailMessage(message);
      }
      if (code == 'status401') {
        return showSnackbarWarningMessage(
          'غير مخول : لاتملك صلاحية الحالة المطلوبة',
        );
      }
      if (code == 'auth401') {
        return showSnackbarWarningMessage('غير مخول : لاتملك صلاحية');
      }
      if (code == 'auth401-create') {
        return showSnackbarWarningMessage('غير مخول : لاتملك صلاحية الانشاء');
      }
      if (code == 'auth401-update') {
        return showSnackbarWarningMessage('غير مخول : لاتملك صلاحية التعديل');
      }

      return showSnackbarFailMessage(message ?? 'حدث خطأ اثناء عملية الحفظ');
    },
    clearInput() {
      const _initData = initData();
      for (const k in this.$data) this.$data[k] = _initData[k];
      this.slug = makeid(15);
      // reset the form validation
      this.$v.$reset();

      this.setAllContentJSON('');
      setTimeout(() => this.pushedSchema(schemaType));
    },
    isInvalid() {
      this.$v.$touch();
      if (this.$v.$invalid) return true;
      return false;
    },
    async getById(id) {
      this.$store.commit('loading/SET_DIALOG_LOADING', true);
      return this.$http
        .get('app/dynamic-content/' + id, { params: { language: 'all' } })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          this.closeDialog(false, true);
          return null;
        })
        .finally(() => this.$store.commit('loading/SET_DIALOG_LOADING', false));
    },
    buildSchemaResult() {
      const results = { invalid: false };
      this.addedLanguages.forEach(({ key }) => {
        const fieldResult = this.$refs[`fieldContoller_${key}`];
        if (!fieldResult || fieldResult?.length == 0) return;
        results[key] = fieldResult[0].getData();
        if (results[key].invalid) results.invalid = true;
      });
      this.schemaResults = results;
    },
    doSubmit(cb) {
      this.buildSchemaResult();

      if (this.isInvalid() || this.schemaResults.invalid) {
        showSnackbarWarningMessage('يجب عدم ترك الاعمدة المطلوبة فارغة');
        return;
      }

      const slug = this.slug;
      const categories = this.categories;
      const contentStatusId =
        (this.contentStatus && this.contentStatus.id) || null;
      const contentTypeId = this.contentTypeId;
      const languages = this.inputLanguages;

      // ------------------------------------
      // validation the title annd description
      // ------------------------------------
      const anyEmptyRequestdValue = languages.find(
        (langDtls) => !langDtls.title || !langDtls.description,
      );
      if (anyEmptyRequestdValue) {
        showSnackbarWarningMessage('يجب عدم ترك العنوان والوصف فارغ');
        return;
      }
      // ------------------------------------

      const isFeature = this.isFeature;
      const isSticky = this.isSticky;
      const parentId = this.parentId;
      const publishDate = toValidDateTime(
        createDateTimeFromString(
          defaultDateFormat(this.publishDate),
          this.time,
        ),
      );

      const expiryDate = this.expiryDate && defaultDateFormat(this.expiryDate);

      const postData = {
        slug,
        categories,
        contentStatusId,
        isFeature,
        isSticky,
        publishDate,
        expiryDate,
        contentTypeId,
        dynamicContentLanguages: languages,
        parentId,
      };
      if (this.isNew) {
        this.doSave(postData).then((status) => {
          if (!status) return;
          cb?.();
        });
      } else {
        // TODO : AH Need Refactor
        this.doUpdate(postData).then((status) => {
          if (!status) return;
          cb?.();
        });
      }
    },
  },
  watch: {
    submitWithClose() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.closeDialog(true);
      });
    },
    submitWithNew() {
      this.doSubmit(() => {
        this.clearInput();
        this.afterSubmit();
        this.setItemID(null);
      });
    },
    submitWithoutClose() {
      this.doSubmit(() => {
        this.afterSubmit();
      });
    },
    formLanguage: {
      deep: true,
      handler(value) {},
    },
    tab() {},
  },
};
</script>

<style lang="scss">
.link-input {
  input {
    direction: ltr;
    text-align: left;
    unicode-bidi: bidi-override;
  }
}
</style>

<style lang="scss" scoped>
.image-container {
  // width: 100px;
  height: 100px;
  margin: 0 5px;
  img {
    height: 100%;
    // width: 100%;
    object-fit: cover;
  }
}
</style>
