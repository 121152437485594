<template>
  <v-list-item three-line>
    <v-list-item-content>
      <v-list-item-title
        class="my-4"
        v-if="
          !typeHelper.isVideo(type) &&
          !typeHelper.isEmbed(type) &&
          !typeHelper.isYoutubeId(type)
        "
      >
        <strong>{{ name }}</strong>
        <span class="text-caption">{{ subTitle }}</span>
        <span class="red--text">{{ requiredStar }}</span>
      </v-list-item-title>
      <v-row>
        <v-col cols="12">
          <!--
            ----------------------------------
            STRING
            ----------------------------------
            ----------------------------------
          -->

          <v-text-field
            v-if="typeHelper.isString(type)"
            v-model="modelValue"
            :error-messages="valueErrors"
            :placeholder="description"
            label="القيمة"
            outlined
            dense
            @blur="$emit('blur')"
          />

          <!--
            ----------------------------------
            LONG STRING
            ----------------------------------
            ----------------------------------
          -->

          <v-textarea
            v-else-if="typeHelper.isLongString(type)"
            v-model="modelValue"
            :error-messages="valueErrors"
            :placeholder="description"
            label="القيمة"
            outlined
            dense
            @blur="$emit('blur')"
          />

          <!--
            ----------------------------------
            NUMBER
            ----------------------------------
            ----------------------------------
          -->

          <v-text-field
            v-else-if="typeHelper.isNumber(type)"
            type="number"
            v-model="modelValue"
            :error-messages="valueErrors"
            :placeholder="description"
            label="القيمة"
            outlined
            dense
            @blur="$emit('blur')"
          />

          <!--
            ----------------------------------
            DATE
            ----------------------------------
            ----------------------------------
          -->

          <DateBase
            v-else-if="typeHelper.isDate(type)"
            v-model="modelValue"
            :error-messages="valueErrors"
            :placeholder="description"
            label="القيمة"
            outlined
            dense
            @blur="$emit('blur')"
          />

          <!--
            ----------------------------------
            UPLOAD IMAGE
            ----------------------------------
            ----------------------------------
          -->
          <template v-else-if="typeHelper.isImage(type)">
            <v-row>
              <v-col cols="12" class="grey lighten-4">
                <div
                  class="fill-height"
                  style="
                    max-height: 500px;
                    max-width: 1000px;
                    min-height: 280px;
                    margin: auto;
                    border-radius: 4px;
                    overflow: hidden;
                  "
                >
                  <img
                    :src="getImageSrc(modelValue, 'md')"
                    v-if="getImageSrc(modelValue, 'sm')"
                    width="100%"
                    class="fill-height ma-0"
                    style="object-fit: cover"
                  />
                  <v-row
                    v-else
                    class="grey lighten-2 fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-icon>mdi-image</v-icon>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" v-if="valueErrors.length > 0">
                <span class="red--text"> الصورة مطللوبة </span>
              </v-col>
              <v-col>
                <UploadImage
                  v-model="modelValue"
                  :multiple="false"
                  text="اختر الصورة"
                />
              </v-col>
            </v-row>
          </template>

          <!--
            ----------------------------------
            EMAIL
            ----------------------------------
            ----------------------------------
          -->

          <v-text-field
            v-else-if="typeHelper.isEmail(type)"
            v-model="modelValue"
            :error-messages="valueErrors"
            :placeholder="description"
            label="القيمة"
            outlined
            dense
            @blur="$emit('blur')"
          />

          <!--
            ----------------------------------
            PHONE NUMBER
            ----------------------------------
            ----------------------------------
          -->

          <v-text-field
            v-else-if="typeHelper.isPhoneNumber(type)"
            v-model="modelValue"
            :error-messages="valueErrors"
            v-mask="'(####) ### ####'"
            label="القيمة"
            outlined
            dense
            dir="ltr"
            @blur="$emit('blur')"
          />

          <!--
            ----------------------------------
            LIST
            ----------------------------------
            ----------------------------------
          -->
          <template
            v-else-if="
              typeHelper.isUnorderedList(type) || typeHelper.isOrderedList(type)
            "
          >
            <v-list subheader two-line v-if="modelList.length != 0">
              <v-list-item v-for="(item, index) in modelList" :key="index">
                <v-list-item-avatar
                  v-if="typeHelper.isUnorderedList(type)"
                  size="10"
                  class="align-self-center"
                >
                  <v-icon class="secondary lighten-1" />
                </v-list-item-avatar>
                <strong v-else v-html="index + 1" class="ml-2" />

                <v-list-item-content>
                  <v-list-item-title>
                    <template v-if="!item.link">
                      {{ item.value }}
                    </template>
                    <a v-else :href="item.link" target="_blank">
                      {{ item.value }}
                    </a>
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click="editItemFromList(index, { link: true })"
                    :class="
                      listItem.link && listItem.index == index
                        ? 'yellow lighten-4'
                        : ''
                    "
                    :disabled="
                      listItem.edit ||
                      (listItem.index != null && listItem.index != index)
                    "
                  >
                    <v-icon
                      color="blue lighten-1"
                      v-html="
                        listItem.link && listItem.index == index
                          ? 'mdi-close'
                          : 'mdi-link-variant'
                      "
                    />
                  </v-btn>
                </v-list-item-action>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click="editItemFromList(index, { edit: true })"
                    :class="
                      listItem.edit && listItem.index == index
                        ? 'yellow lighten-4'
                        : ''
                    "
                    :disabled="
                      listItem.link ||
                      (listItem.index != null && listItem.index != index)
                    "
                  >
                    <v-icon
                      color="orange lighten-1"
                      v-html="
                        listItem.edit && listItem.index == index
                          ? 'mdi-close'
                          : 'mdi-pencil'
                      "
                    />
                  </v-btn>
                </v-list-item-action>

                <v-list-item-action class="mx-0">
                  <v-btn
                    icon
                    @click="removeItemFromList(index)"
                    :disabled="
                      (listItem.edit || listItem.link) && listItem.index != null
                    "
                  >
                    <v-icon color="red lighten-1">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-text-field
              prepend-inner-icon="mdi-plus"
              :error-messages="valueErrors"
              placeholder="اضافة/تعديل عنصر"
              label="عنصر"
              outlined
              dense
              v-model="listItem.val"
              @keypress.enter="addUpdateItemList"
              @blur="$emit('blur')"
              class="my-2"
            />
          </template>

          <!--
            ----------------------------------
            BOOLEAN
            ----------------------------------
            ----------------------------------
          -->
          <v-checkbox
            v-else-if="typeHelper.isBoolean(type)"
            v-model="modelValue"
            :error-messages="valueErrors"
            v-mask="'(####) ### ####'"
            label="نعم / كلا"
            class="mx-2"
          />

          <!--
            ----------------------------------
            Video
            ----------------------------------
            ----------------------------------
          -->
          <template v-else-if="typeHelper.isVideo(type)">
            <v-row>
              <v-col cols="6" class="d-flex flex-column">
                <v-row>
                  <v-col cols="12">
                    <v-subheader>{{ name }}</v-subheader>
                  </v-col>
                  <v-col cols="12" class="d-flex align-end">
                    <UploadVideo
                      v-model="modelValue"
                      :multiple="false"
                      text="اختر الفديو"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-col cols="12" dark class="background">
                  <div
                    style="
                      max-height: 200px;
                      max-width: 1000px;
                      margin: auto;
                      border-radius: 4px;
                      overflow: hidden;
                      aspect-ratio: 16/9;
                    "
                  >
                    <video
                      v-if="getVideoSrc(modelValue)"
                      :src="getVideoSrc(modelValue)"
                      controls
                      style="height: 200px; aspect-ratio: 16/9"
                    />
                    <v-row
                      v-else
                      class="background fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-icon large>mdi-video</v-icon>
                      <span class="red--text" v-if="valueErrors.length > 0">
                        الفديو مطلوب
                      </span>
                    </v-row>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </template>

          <!--
            ----------------------------------
            Embed Ex : Video
            ----------------------------------
            ----------------------------------
          -->
          <template v-else-if="typeHelper.isEmbed(type)">
            <v-row>
              <v-col cols="6" class="fill-hieght d-flex flex-column">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-subheader>{{ name }}</v-subheader>
                  </v-col>
                  <v-col class="d-flex align-end pb-4">
                    <v-textarea
                      v-model="modelValue"
                      :error-messages="valueErrors"
                      placeholder="your embed"
                      label="embed"
                      dense
                      no-resize
                      filled
                      row-height="20px"
                      height="140px"
                      full-width
                      class="pb-0"
                      hide-details="auto"
                      style="direction: ltr"
                      @blur="$emit('blur')"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-col cols="12" dark class="background">
                  <div
                    v-html="changeEmbedStyle()"
                    style="
                      max-height: 200px;
                      max-width: 1000px;
                      margin: auto;
                      border-radius: 4px;
                      overflow: hidden;
                      aspect-ratio: 16/9;
                    "
                  ></div>
                </v-col>
              </v-col>
            </v-row>
          </template>

          <!--
            ----------------------------------
            YoutubeId
            ----------------------------------
            ----------------------------------

          -->
          <template v-else-if="typeHelper.isYoutubeId(type)">
            <v-row>
              <v-col cols="6" class="d-flex flex-column">
                <v-row>
                  <v-col cols="12">
                    <v-subheader>{{ name }}</v-subheader>
                  </v-col>
                  <v-col class="d-flex align-end pb-4">
                    <v-text-field
                      v-model="modelValue"
                      :error-messages="valueErrors"
                      :placeholder="description"
                      label="قيمة معرف اليوتويب"
                      outlined
                      dense
                      hide-details="auto"
                      @blur="$emit('blur')"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-col cols="12" dark class="background">
                  <div
                    style="
                      max-height: 200px;
                      max-width: 1000px;
                      margin: auto;
                      border-radius: 4px;
                      overflow: hidden;
                      aspect-ratio: 16/9;
                    "
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      :src="`https://www.youtube.com/embed/${modelValue}`"
                    >
                    </iframe>
                  </div>
                </v-col>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { typeHelper } from './utls/FieldTypes';
import DateBase from '@/components/DateBase.vue';
import UploadVideo from '../UploadManager/UploadVideo.vue';
import UploadImage from '../UploadManager/UploadImage.vue';
import { getImageURL, getVideoURL } from '@/helper/cmsPaths';

export default {
  components: {
    DateBase,
    UploadVideo,
    UploadImage,
  },
  props: {
    selectedLanguage: {
      type: String,
      requierd: true,
    },
    field: {
      type: Object,
      requierd: true,
    },
    valid: {
      type: Object,
      requierd: true,
    },
    value: {},
    valueErrors: {},
  },
  data() {
    return {
      listItem: {
        index: null,
        edit: false,
        link: false,
        val: '',
      },
    };
  },
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    description() {
      return this.field.description[this.selectedLanguage];
    },
    subTitle() {
      return this.description ? ' (' + this.description + ') ' : '';
    },
    name() {
      return this.field.name[this.selectedLanguage];
    },
    requiredStar() {
      const isRequired = this.field.isRequired;
      if (isRequired) return '*';
      return '';
    },
    type() {
      return this.field.type;
    },
    typeHelper() {
      return typeHelper;
    },
    modelList: {
      get() {
        try {
          // convert string-json to list
          if (!this.modelValue) return [];
          const listOfItem = JSON.parse(this.modelValue);
          return listOfItem;
        } catch (error) {
          return [];
        }
      },
      set(list) {
        try {
          // convert list to string-json
          const json = JSON.stringify(list);
          this.modelValue = json;
        } catch (error) {
          return '';
        }
      },
    },
  },
  methods: {
    getVideoSrc(name) {
      return getVideoURL(name);
    },
    getImageSrc(name, size) {
      return getImageURL(name, size);
    },
    addUpdateItemList({ target: { value } }) {
      if (this.listItem.edit && !value) return;
      const list = this.modelList;

      if (this.listItem.edit) {
        // update index
        const item = list[this.listItem.index];
        item.value = value;
      } else if (this.listItem.link) {
        // update index
        const item = list[this.listItem.index];
        item.link = value;
      } else {
        // add new
        const newItem = { value: value.replace('|', '@') };
        list.push(newItem);
      }
      this.modelList = list;

      // reset current selected item
      this.updateItemList({});
    },
    removeItemFromList(index) {
      const list = this.modelList;
      list.splice(index, 1);
      this.modelList = list;
    },
    editItemFromList(index, { link, edit }) {
      // cancel edit
      if (this.listItem.index != null) {
        this.updateItemList({});
        return;
      }

      const list = this.modelList;
      // open edit
      if (edit) {
        const { value: itemValue = '' } = list[index];
        this.updateItemList({ index, val: itemValue, edit: true });
      } else if (link) {
        const { link: itemValue = '' } = list[index];
        this.updateItemList({ index, val: itemValue, link: true });
      }
    },
    updateItemList({ index = null, val = '', edit = false, link = false }) {
      this.listItem.index = index;
      this.listItem.val = val;
      this.listItem.edit = edit;
      this.listItem.link = link;
    },
    changeEmbedStyle() {
      const embed = this.modelValue;
      const newEmbed = embed
        .replace(/width=\"[0-9]+\"/i, 'width="100%"')
        .replace(/height=\"[0-9]+\"/i, 'height="100%"');
      return newEmbed;
    },
  },
  //   watch: {
  //     modelValue(newValue, oldValue) {
  //       console.log({ newValue, oldValue });
  //     },
  //   },
};
</script>

<style lang="scss" scoped></style>
