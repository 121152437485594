<template>
  <v-card elevation="0" outlined>
    <!-- TABS HEADER -->
    <v-toolbar flat :style="getTabHeaderStyle">
      <v-tabs v-model="tab">
        <v-tabs-slider color="white darken-1"></v-tabs-slider>
        <v-tab
          v-for="lang in languagesKeysValue"
          :key="lang.key"
          class="font-weight-black"
          active-class="primary lighten-1 white--text"
        >
          {{ lang.value }}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <!-- TABS CONTENT -->
    <v-tabs-items class="tbl" v-model="tab">
      <v-tab-item v-for="lang in languagesKeysValue" :key="lang.key">
        <v-container fluid :class="render ? 'pa-0 ma-0' : ''">
          <template v-if="render">
            <v-row>
              <v-col cols="12" class="pa-8">
                <table class="full-width-table elevation-1">
                  <thead>
                    <tr>
                      <th
                        v-for="(h, idx) in headers"
                        :key="idx"
                        class="text-start"
                      >
                        {{ h.text }}
                      </th>
                      <th class="action" />
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(row, rIdx) in headerRows" :key="rIdx">
                      <td
                        v-for="(cell, cIdx) in row"
                        :key="cIdx"
                        class="text-start"
                      >
                        <v-textarea
                          v-model="headerRows[rIdx][cIdx]"
                          auto-grow
                          rows="2"
                        />
                      </td>

                      <td class="action justify-center align-center">
                        <!-- <v-btn
                          v-if="editRowIdx !== rIdx"
                          icon
                          color="warning"
                          @click="editRow(rIdx)"
                          :disabled="editRowIdx != null"
                        >
                          <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>

                        <v-btn
                          v-else
                          icon
                          color="warning"
                          @click="cancelEditRow(rIdx)"
                        >
                          <v-icon dark> mdi-cancel </v-icon>
                        </v-btn> -->

                        <v-btn
                          color="pink"
                          @click="removeRow(rIdx)"
                          :disabled="editRowIdx != null"
                          fab
                          small
                        >
                          <v-icon color="white"> mdi-delete </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <!-- 
                    <div v-if="!rows.length" class="pa-4 text-center">
                      لاتوجد بيانات
                    </div> 
                  -->

                    <tr>
                      <td
                        v-for="(h, idx) in headers"
                        :key="idx"
                        class="pb-0"
                        :cols="h.src ? 12 : undefined"
                      >
                        <Autocomplete
                          v-if="h.src == 1"
                          v-model="row[h.id]"
                          :url="h.src"
                          :label="h.text"
                          :ref="`h${h.id}`"
                        />
                        <v-textarea
                          v-else
                          v-model="row[h.id]"
                          :placeholder="h.text"
                          :ref="`h${h.id}`"
                          auto-grow
                          rows="2"
                        />
                      </td>
                      <td class="action justify-center align-center">
                        <!-- <v-btn
                          v-if="editRowIdx == null"
                          color="primary"
                          @click="addRow"
                        >
                          اضافة
                        </v-btn>
                        
                        <v-btn v-else color="primary" @click="saveRow">
                          حفظ
                        </v-btn>
                         -->
                        <v-btn color="primary" fab small @click="addRow">
                          <v-icon dark> mdi-plus </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </template>

          <v-row v-else>
            <v-col cols="12">
              <MultiSelect
                v-model="fieldLanguages.header[`${lang.key}`]"
                :label="'الاعمدة بالـ ' + lang.value"
                itemColor="grey lighten-3"
              >
                <template #item="{ item }">
                  <b>{{ splitQuotedText(item)[0] }}</b>
                  <div class="mx-1" style="color: #009200">
                    {{ splitQuotedText(item)[1] }}
                  </div>
                </template>
              </MultiSelect>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { languagesKeys, languagesKeysValue } from '@/config/config';
import MultiSelect from '@/components/MultiSelect.vue';
import { splitQuotedText } from '@/helper/commonjs.js';
import Autocomplete from '@/components/Autocomplete.vue';

const buildLanguageKeysWithEmptyValue = (selected) => {
  const langKeys = { ...languagesKeys() };
  for (var key in langKeys) {
    // for all fieldLanguages
    // or
    // for only selectedLanguage
    if (selected && selected != key) {
      delete langKeys[key];
    } else if (key) langKeys[key] = '';
  }
  return langKeys;
};

const getLanguagesKeysValue = (selected) => {
  if (!selected) return languagesKeysValue();
  return languagesKeysValue().filter((lang) => lang.key == selected);
};

export default {
  props: ['value', 'selectedLanguage', 'render'],
  data() {
    return {
      tab: null,
      languagesKeysValue: getLanguagesKeysValue(this.selectedLanguage),
      fieldLanguages: {
        header: {
          ...buildLanguageKeysWithEmptyValue(this.selectedLanguage),
        },
        rows: {
          ...buildLanguageKeysWithEmptyValue(this.selectedLanguage),
        },
      },
      row: [],
      editRowIdx: null,
    };
  },
  components: {
    MultiSelect,
    Autocomplete,
  },
  computed: {
    headers() {
      return this.header.split(',').map((name, idx) => ({
        text: splitQuotedText(name)[0],
        src: splitQuotedText(name)[1],
        id: idx,
      }));
    },
    getTabHeaderStyle() {
      if (this.selectedLanguage) return 'display: none;';
      else return '';
    },
    header() {
      return this.fieldLanguages.header[this.selectedLanguage];
    },
    headerRows() {;
      if (!Array.isArray(this.fieldLanguages.rows[this.selectedLanguage])) this.fieldLanguages.rows[this.selectedLanguage] = [];

      return this.fieldLanguages.rows[this.selectedLanguage];
    },
  },
  methods: {
    addRow() {
      if (!this.row.length) return;

      if (this.row.length != this.headers.length) {
        const dif = this.headers.length - this.row.length;
        for (var i = 0; i < dif; i++) this.row.push('');
      }

      this.headerRows.push(this.row);
      this.row = [];
    },
    saveRow() {
      this.headerRows[this.editRowIdx] = [...this.row];
      this.cancelEditRow();
    },
    removeRow(idx) {
      this.headerRows.splice(idx, 1);
    },
    editRow(idx) {
      this.editRowIdx = idx;
      this.row = [...this.headerRows[idx]];
    },
    cancelEditRow() {
      this.editRowIdx = null;
      this.row = [];
    },
    splitQuotedText(text) {
      return splitQuotedText(text);
    },
  },
  watch: {
    fieldLanguages: {
      handler(value) {
        const updatedValue = { ...this.value, ...value };
        this.$emit('input', updatedValue);
      },
      deep: true,
    },
  },
  created() {
    if (!this.value) return;
    for (const k in this.fieldLanguages) {
      for (const languageCode in this.fieldLanguages[k]) {
        const prop = this.value[k];
        if (prop) this.fieldLanguages[k][languageCode] = prop[languageCode];
      }
    }
  },
};
</script>

<style scoped lang="scss">
.tbl {
  .full-width-table {
    width: 100%; /* Set the table to 100% width */
    border-collapse: collapse; /* Optional: To collapse borders */
    th,
    td {
      width: 100%;
      border: 1px solid #f0f0f0; /* Optional: Add border for cells */
      padding: 8px; /* Optional: Add padding to cells */
    }
    th {
      background: #3a8fff0c;
      font-size: 1.1rem;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    td {
      font-size: 1rem;
      display: flex;
      justify-content: center;
    }
  }

  tr {
    display: flex;
  }
  .action {
    flex-basis: 350px;
    display: flex;
  }
}
</style>
