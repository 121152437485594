<template>
  <v-row class="fill-height" style="position: relative">
    <!-- Image Of Slider -->
    <img
      :src="getSliderItemSrc(field.src)"
      style="
        position: absolute;
        width: 100%;
        height: 110%;
        object-fit: cover;
        background-color: #eee;
      "
    />

    <!-- Description Of Slider -->
    <v-row align="end" class="fill-height mx-10">
      <v-card elevation="0" outlined :style="cardStyle" class="ma-10">
        <v-container>
          <v-row>
            <v-col cols="12">
              <SliderImageLanguages
                v-model="field"
                :selectedLanguage="selectedLanguage"
              />
            </v-col>
          </v-row>

          <v-row style="display: none">
            <v-col cols="6">
              <v-text-field v-model="field.src" label="الصورة" outlined />
            </v-col>

            <v-col cols="6">
              <v-text-field
                type="number"
                v-model="field.order"
                label="الترتيب"
                placeholder="الترتيب"
                required
                outlined
                :error-messages="orderErrors"
                @input="$v.field.order.$touch()"
                @blur="$v.field.order.$touch()"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="field.slug" label="slug" outlined />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-btn
                color="pink"
                class="mx-1 white--text"
                :loading="cardStyle.borderColor == 'red'"
                @click="removeField"
                v-if="canDeleteField"
              >
                مسح
                <v-icon color="#fff">mdi-delete</v-icon>
              </v-btn>
              <UploadImage
                v-model="field.src"
                :multiple="false"
                text="اختر الصورة"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import SliderImageLanguages from './SliderImageLanguages.vue';
import UploadImage from '../UploadManager/UploadImage.vue';
import { makeid } from '@/utls/jsCommon';
import { getImageURL } from '@/helper/cmsPaths';

export default {
  props: ['data', 'selectedLanguage', 'hideDeleteField', 'render'],
  components: {
    SliderImageLanguages,
    UploadImage,
  },
  mixins: [validationMixin],
  validations: {
    field: {
      order: {
        required,
      },
      // src: {
      //   required,
      // },
    },
  },
  data() {
    return {
      field: {
        description: {}, // multi languages { ar: value, en: value }
        order: '1',
        src: '',
        slug: '',
      },
      cardStyle: {
        width: '500px',
        border: '2px solid',
        borderColor: 'var(--primary-color)',
        padding: '20px 10px 0',
      },
    };
  },
  computed: {
    orderErrors() {
      const errors = [];
      if (!this.$v.field.order.$dirty) return errors;
      !this.$v.field.order.required && errors.push('الحقل مطلوب');
      return errors;
    },
    // srcErrors() {
    //   const errors = [];
    //   if (!this.$v.field.src.$dirty) return errors;
    //   !this.$v.field.src.required && errors.push('الحقل مطلوب');
    //   return errors;
    // },
    language() {},
    canDeleteField() {
      if (this.hideDeleteField == true) return false;
      return true;
    },
  },

  methods: {
    getSliderItemSrc(src) {
      if (!src) return;
      return this.getImageSrc(src, 'md');
    },
    getImageSrc(name, size) {
      return getImageURL(name, size);
    },
    removeField() {
      this.cardStyle.borderColor = 'red';
      setTimeout(() => this.$emit('remove', this.data.id), 600);
    },
    initField() {
      if (this.data) this.field = this.data;
      if (!this.field.slug) this.field.slug = makeid(6);
    },
    changeCardBorderColor() {
      const isDark = this.$store.state.theme.dark;

      let bcolor = 'var(--primary-color)';
      if (this.render && isDark) bcolor = '#555';
      else if (this.render) bcolor = '#aaaccc';

      this.cardStyle.borderColor = bcolor;
    },
  },
  created() {
    this.initField();
  },
  mounted() {
    this.changeCardBorderColor();
  },
};
</script>

<style lang="scss" scoped></style>
