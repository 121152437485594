<template>
  <v-card elevation="0" outlined>
    <!-- TABS HEADER -->
    <v-toolbar flat :style="getTabHeaderStyle">
      <v-tabs v-model="tab">
        <v-tabs-slider color="white darken-1"></v-tabs-slider>
        <v-tab
          v-for="lang in languagesKeysValue"
          :key="lang.key"
          class="font-weight-black"
          active-class="primary lighten-1 white--text"
        >
          {{ lang.value }}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <!-- TABS CONTENT -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="lang in languagesKeysValue" :key="lang.key">
        <v-card flat>
          <v-container fluid>
            <template v-if="render">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1" v-text="name" />
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-row v-else>
              <v-col cols="12">
                <v-text-field
                  v-model="fieldLanguages.name[`${lang.key}`]"
                  :counter="200"
                  :label="'الاسم بالـ ' + lang.value"
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { languagesKeys, languagesKeysValue } from '@/config/config';

const buildLanguageKeysWithEmptyValue = (selected) => {
  const langKeys = { ...languagesKeys() };
  for (var key in langKeys) {
    // for all fieldLanguages
    // or
    // for only selectedLanguage
    if (selected && selected != key) {
      delete langKeys[key];
    } else if (key) langKeys[key] = '';
  }
  return langKeys;
};

const getLanguagesKeysValue = (selected) => {
  if (!selected) return languagesKeysValue();
  return languagesKeysValue().filter((lang) => lang.key == selected);
};

export default {
  props: ['value', 'selectedLanguage', 'render'],
  data() {
    return {
      tab: null,
      languagesKeysValue: getLanguagesKeysValue(this.selectedLanguage),
      fieldLanguages: {
        name: {
          ...buildLanguageKeysWithEmptyValue(this.selectedLanguage),
        }, // { ar:{ }, en:{ } }
      },
    };
  },
  computed: {
    getTabHeaderStyle() {
      if (this.selectedLanguage) return 'display: none;';
      else return '';
    },
    name() {
      return this.fieldLanguages.name[this.selectedLanguage];
    },
  },
  watch: {
    fieldLanguages: {
      handler(value) {
        const updatedValue = { ...this.value, ...value };
        this.$emit('input', updatedValue);
      },
      deep: true,
    },
  },
  created() {
    if (!this.value) return;
    for (const k in this.fieldLanguages) {
      for (const languageCode in this.fieldLanguages[k]) {
        const prop = this.value[k];
        if (prop) this.fieldLanguages[k][languageCode] = prop[languageCode];
      }
    }
  },
};
</script>
