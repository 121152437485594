<template>
  <v-dialog ref="dialog" v-model="modal" width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        v-bind="{ ...attrs, ...$attrs }"
        v-on="on"
        :label="label"
        readonly
        hide-details="auto"
        dense
        :clearable="!!clearable"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" scrollable @input="modal = false" />
  </v-dialog>
</template>

<script>
import { defaultDateFormat } from '@/utls/jsCommon';
export default {
  props: ['value', 'label', 'empty', 'clearable'],
  data: () => ({
    modal: false,
  }),
  computed: {
    date: {
      get() {
        if (this.empty === true && this.value == null) return '';
        let dd = defaultDateFormat(this.value);
        return dd;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  inheritAttrs: false,
};
</script>
