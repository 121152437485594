<template>
  <v-card elevation="0" outlined>
    <!-- TABS HEADER -->
    <v-toolbar flat :style="getTabHeaderStyle">
      <v-tabs v-model="tab">
        <v-tabs-slider color="white darken-1"></v-tabs-slider>
        <v-tab
          v-for="lang in languagesKeysValue"
          :key="lang.key"
          class="font-weight-black"
          active-class="primary lighten-1 white--text"
        >
          {{ lang.value }}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <!-- TABS CONTENT -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="lang in languagesKeysValue" :key="lang.key">
        <v-card flat>
          <v-container fluid>
            <v-row>
              <v-col cols="1">
                <div
                  v-if="fieldLanguages.src[`${lang.key}`]"
                  class="ma-0 d-flex justify-center align-center"
                  :style="{
                    background: getColor(fieldLanguages.src[`${lang.key}`]),
                  }"
                  justify="center"
                  style="height: 100px; width: 100px"
                >
                  <v-icon color="white" x-large>
                    {{ getIcon(fieldLanguages.src[`${lang.key}`]) }}
                  </v-icon>
                </div>
              </v-col>
              <v-col cols="12 pb-0 mb-0">
                <v-text-field
                  v-model="fieldLanguages.desc[`${lang.key}`]"
                  :counter="200"
                  :label="'التفاصيل بالـ ' + lang.value"
                  outlined
                />
              </v-col>

              <v-col cols="6" class="pt-0 mt-0">
                <!-- <v-text-field
                  v-model="fieldLanguages.src[`${lang.key}`]"
                  :label="'الفايل بالـ ' + lang.value"
                  outlined
                />
                 -->
                <UploadDocument
                  v-model="fieldLanguages.src[`${lang.key}`]"
                  :selectedLajnguage="lang.key"
                  :multiple="false"
                  text="اختر المستند"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { languagesKeys, languagesKeysValue } from '@/config/config';
import UploadDocument from '../UploadManager/UploadDocument.vue';

const buildLanguageKeysWithEmptyValue = (selected) => {
  const langKeys = { ...languagesKeys() };
  for (var key in langKeys) {
    // for all fieldLanguages
    // or
    // for only selectedLanguage
    if (selected && selected != key) {
      delete langKeys[key];
    } else if (key) langKeys[key] = '';
  }
  return langKeys;
};

const getLanguagesKeysValue = (selected) => {
  if (!selected) return languagesKeysValue();
  return languagesKeysValue().filter((lang) => lang.key == selected);
};

export default {
  props: ['value', 'selectedLanguage'],
  data() {
    return {
      tab: null,
      languagesKeysValue: getLanguagesKeysValue(this.selectedLanguage),
      fieldLanguages: {
        src: {
          ...buildLanguageKeysWithEmptyValue(this.selectedLanguage),
        },
        desc: {
          ...buildLanguageKeysWithEmptyValue(this.selectedLanguage),
        }, // { ar:{ }, en:{ } }
      },
    };
  },
  computed: {
    getTabHeaderStyle() {
      if (this.selectedLanguage) return 'display: none;';
      else return '';
    },
  },
  watch: {
    fieldLanguages: {
      handler(value) {
        const updatedValue = { ...this.value, ...value };
        this.$emit('input', updatedValue);
      },
      deep: true,
    },
  },
  methods: {
    getType(src) {
      if (!src) return {};
      if (src.endsWith('.docx')) return { word: true };
      if (src.endsWith('.pdf')) return { pdf: true };
      if (src.endsWith('.xlsx')) return { excel: true };
      return {};
    },
    getColor(src) {
      if (!src) return 'grey';
      const type = this.getType(src);
      if (type.word) return '#2b579a';
      if (type.pdf) return '#a40000 ';
      if (type.excel) return '#217346';
      return '#7674a596 !important';
    },
    getIcon(src) {
      if (!src) return 'mdi-text-box';
      const type = this.getType(src);
      if (type.word) return 'mdi-file-word-box';
      if (type.pdf) return 'mdi-file-pdf-box';
      if (type.excel) return 'mdi-file-excel-outline';
      return 'mdi-file-document-check-outline';
    },
  },
  created() {
    for (const k in this.fieldLanguages) {
      for (const languageCode in this.fieldLanguages[k]) {
        const prop = this.value[k];
        if (prop) this.fieldLanguages[k][languageCode] = prop[languageCode];
      }
    }
  },
  components: { UploadDocument },
};
</script>
