<template>
  <UploadManager
    v-model="documentModel"
    :selectedLajnguage="selectedLajnguage"
    :buttonTitle="buttonTitle"
    :buttonIcon="buttonIcon"
    :multiple="multiple"
    uploadNotes="upload document from upload document manager"
    uploadType="document"
    accept="application/pdf, application/msword, application/vnd.ms-excel, .xlsx, .xls, .doc, .docx, .pdf"
    browseFileIcon="mdi-text-box-plus"
    title="مستند"
    gridTitle="اختر مستند"
    uploadTitle="تحميل مستند"
    selectFileToUploadMsg="اختر المستند المراد تحميله"
    successUploadMsg="تم تحميل المستند"
    fileNameLabel="اسم المستند"
  >
    <!-- ----------------------------------------------------------------- -->
    <!-- filesGridPreview -->
    <!-- ----------------------------------------------------------------- -->
    <template
      #filesGridPreview="{
        files,
        selectFile,
        getSelectedFileClass,
        isSelected,
        remove,
        isLoading: fileLoading,
        editFile,
      }"
    >
      <v-col cols="12"> </v-col>
      <v-col
        v-for="({ src: fileSrc, alt, id: fileId, creator }, index) in files"
        :key="index"
        class="d-flex child-flex"
        cols="6"
        xs="6"
        sm="4"
        md="3"
        lg="2"
        xl="2"
      >
        <v-card
          @click="selectFile(fileSrc)"
          outlined
          elevation="0"
          class="d-flex flex-column"
          :class="`${getSelectedFileClass(fileSrc)}`"
        >
          <v-card-text class="document-conainer pa-0">
            <v-card-actions class="centerFloat">
              <v-btn
                small
                class="btnHide error darken-2"
                @click="remove(fileId)"
                :disabled="fileLoading"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                small
                class="btnHide"
                @click.stop="editFile(fileId, alt, getUrl(fileSrc))"
                :disabled="fileLoading"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>

            <v-row
              class="ma-0"
              :style="{ background: getColor(fileSrc) }"
              justify="center"
              style="height: 100px"
            >
              <v-icon x-large color="white"> {{ getIcon(fileSrc) }} </v-icon>
            </v-row>
          </v-card-text>
          <div class="d-flex justify-space-between flex-column flex-grow-1">
            <v-card-title class="subtitle-1 pb-1"> {{ alt }} </v-card-title>
            <v-card-subtitle class="subtitle-2 pb-1 pt-0 ma-0">
              <v-chip x-small>
                <v-icon x-small class="ml-1">mdi-account</v-icon>
                {{ getUserName(creator) }}
              </v-chip>
            </v-card-subtitle>
          </div>
        </v-card>
      </v-col>
    </template>

    <!-- ----------------------------------------------------------------- -->
    <!-- uploadfilePreview -->
    <!-- ----------------------------------------------------------------- -->
    <template #uploadfilePreview="{ browseFile, fileSrc }">
      <v-fade-transition mode="out-in">
        <div class="file-container" @click="browseFile">
          <v-row
            class="ma-0"
            :style="{ background: getColor(fileSrc), height: '90%' }"
            align="center"
            justify="center"
          >
            <v-icon v-if="!getType(fileSrc).pdf" x-large color="white">
              {{ getIcon(fileSrc) }}
            </v-icon>
            <iframe
              v-if="getType(fileSrc).pdf"
              :src="fileSrc"
              width="100%"
              height="100%"
            ></iframe>
          </v-row>
          <v-row
            @click.stop="downloadFile(fileSrc)"
            class="ma-0"
            style="height: 10%"
            justify="center"
            align="center"
          >
            <v-icon x-large>mdi-download</v-icon>
          </v-row>
        </div>
      </v-fade-transition>
    </template>

    <!-- ----------------------------------------------------------------- -->
    <!-- selectedFiles -->
    <!-- ----------------------------------------------------------------- -->
    <template #selectedFiles="{ files, selectFile }">
      <v-subheader>المستند المختار</v-subheader>
      <v-sheet class="mx-auto" height="120px">
        <v-slide-group multiple show-arrows :center-active="true">
          <v-slide-item v-for="(fileSrc, index) in files" :key="index">
            <v-card outlined elevation="0" class="selected-file-container">
              <div style="height: 100%">
                <div
                  class="close d-flex justify-center"
                  @click="selectFile(fileSrc)"
                >
                  <v-icon color="white">mdi-close</v-icon>
                </div>

                <v-row
                  class="ma-0"
                  :style="{ background: getColor(fileSrc) }"
                  justify="center"
                  style="height: 100%; width: 100%"
                >
                  <v-icon color="white"> {{ getIcon(fileSrc) }} </v-icon>
                </v-row>
              </div>
              <!-- <v-card-title class="selected-caption subtitle-2">
                {{ getAlt(fileSrc) }}
              </v-card-title> -->
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </template>
    <!-- ----------------------------------------------------------------- -->
  </UploadManager>
</template>

<script>
import UploadManager from './UploadManager.vue';
import { getFileURL } from '@/helper/cmsPaths';
export default {
  props: ['value', 'selectedLajnguage', 'text', 'icon', 'multiple'],
  components: { UploadManager },
  computed: {
    documentModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    buttonTitle() {
      return this.text || 'اضافة/الغاء مستند';
    },
    buttonIcon() {
      return this.icon || 'mdi-file-document-outline';
    },
  },
  methods: {
    getType(src) {
      if (!src) return {};
      if (src.endsWith('.docx')) return { word: true };
      if (src.endsWith('.pdf')) return { pdf: true };
      if (src.endsWith('.xlsx')) return { excel: true };
      return {};
    },
    getColor(src) {
      if (!src) return 'grey';
      const type = this.getType(src);
      if (type.word) return '#2b579a';
      if (type.pdf) return '#a40000 ';
      if (type.excel) return '#217346';
      return '#7674a596 !important';
    },
    getIcon(src) {
      if (!src) return 'mdi-text-box';
      const type = this.getType(src);
      if (type.word) return 'mdi-file-word-box';
      if (type.pdf) return 'mdi-file-pdf-box';
      if (type.excel) return 'mdi-file-excel-outline';
      return 'mdi-file-document-check-outline';
    },
    getUrl(src) {
      return getFileURL(src);
    },
    downloadFile(fileSrc) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = fileSrc;
      a.download = 'filename-to-save-as';
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getUserName(creator) {
      return creator?.userName || '';
    },
  },
};
</script>

<style lang="scss" scoped>
.document-conainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .centerFloat {
    position: absolute;
    z-index: 1;
  }

  .btnHide {
    opacity: 0;
  }

  &:hover {
    .btnHide {
      opacity: 1;
    }
  }
}

.file-container {
  height: 70vh;
  border: 1px solid #999 !important;
  padding: 2px;
}

.selected-file-container {
  position: relative;
  margin: 8px;
  height: 125px;
  width: 120px;

  img {
    width: 100%;
    height: 100%;
    margin: 0 !important;
    object-fit: cover;
  }

  .close {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    /* Black see-through */
    width: 100%;
    height: 100%;
    transition: 0.5s ease;
    opacity: 0;
    z-index: 1;
  }

  &:hover .close {
    opacity: 1;
    cursor: pointer;
  }

  .selected-caption {
    white-space: nowrap;
    width: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
